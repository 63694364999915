/**
 * @module FilterSelect
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import ChevronUp from './ChevronUp';
import { COLOR_THEMES } from '../../helpers/constants';
import './FilterSelect.scss';

/**
 * Represents a component that displays a dropdown filter menu.
 *
 * @param {object} props - The component props object.
 * @param {string} [props.className] - The additional CSS class name for custom styling.
 * @param {Function} props.onChange - Callback function triggered when the select value changes.
 * @param {string} props.theme - The current theme of the application.
 *
 * @returns {React.ReactElement} - The FilterSelect component.
 */
function FilterSelect({ className, onChange, theme }) {
  /**
   * Handles the onChange event of the select element.
   *
   * @param {Event} event - The Event object associated with the change.
   */
  function handleOnChange(event) {
    if (onChange && typeof onChange === 'function') {
      onChange(event);
    }
  }

  const themeClassName = theme === COLOR_THEMES['dark-mode'] ? 'dark' : 'light';
  const customClassName = className || '';

  return (
    <div
      className={`filter-select-wrapper ${themeClassName} ${customClassName}`.trim()}
      data-testid="lc-filter-select"
    >
      <select className="filter-select" onChange={handleOnChange}>
        <option value={'new'}>Newest to Oldest</option>
        <option value={'old'}>Oldest to Newest</option>
        <option value={'a'}>A to Z</option>
        <option value={'z'}>Z to A</option>
      </select>
      <ChevronUp />
    </div>
  );
}
export default FilterSelect;
