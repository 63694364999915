/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/**
 * @module TopicsListing
 */
import React from 'react';
import { Configure, Index, InstantSearch, useHits } from 'react-instantsearch';
import Slider from 'react-slick';
import { callSegmentTrack } from '@io/web-tools-io/dist/utils/helpers/analytics';
import useAuth from '@io/web-tools-io/dist/hooks/useAuth';
import useWindowSize from '@io/web-tools-io/dist/hooks/useWindowSize';
import ArrowButton from '../ArrowButton/ArrowButton';
import FilterSelect from '../FilterSelect/FilterSelect';
import useAlgoliaClient from '../../hooks/useAlgoliaClient';
import ArticleCard from '../ArticleCard/ArticleCard';
import ImageWithParts from '../ImageWithParts/ImageWithParts';
import FindsSearchBox from '../FindsSearchBox/FindsSearchBox';
import SearchResultsText from '../SearchResultsText/SearchResultsText';
import { ACTIONS, EVENTS } from '../../helpers/constants';
import './TopicsListing.scss';

const TopicsListing = (props) => {
  const { user } = useAuth();
  const urlParams = new URLSearchParams(window.location.search);
  const { title, theme, predefinedTopic } = props;
  const { isMobile } = useWindowSize();
  const searchClient = useAlgoliaClient();
  const [hitNumJourney, setHitNumJourney] = React.useState(0);
  const [hitNumArticle, setHitNumArticle] = React.useState(0);
  const [pageJourneys, setPageJourneys] = React.useState(0);
  const [pageArticles, setPageArticles] = React.useState(0);
  const [order, setOrder] = React.useState('new');
  const [searchInput, setSearchInput] = React.useState('');
  const childRef = React.useRef(null);
  let articleSliderRef = React.useRef(null);
  let journeySliderRef = React.useRef(null);
  const tagFromParams = urlParams.get('includeFilter');
  const tags =
    !!props.metadata && !!props.metadata['mgnl:tags']
      ? props.metadata['mgnl:tags']
      : [];
  const tagFilters = tagFromParams ? [tagFromParams, ...tags] : tags;
  const settingsJourney = {
    accessibility: false,
    arrows: false,
    beforeChange: (old, curr) => {
      setPageJourneys(curr < 0 ? 0 : curr);
    },
    infinite: false,
  };
  const articleTopRef = React.useRef(null);
  const journeyTopRef = React.useRef(null);
  const scrollToSection = (topRef) => {
    if (topRef.current) {
      topRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const getActiveTag = (tag) => (tagFilters?.includes(tag) ? tag : '');
  const [filter, setFilter] = React.useState(
    predefinedTopic || getActiveTag(urlParams.get('activeTag')?.toLowerCase()),
  );

  const settingsArticle = {
    accessibility: false,
    arrows: false,
    beforeChange: (old, curr) => {
      setPageArticles(curr < 0 ? 0 : curr);
    },
    infinite: false,
  };

  const getNumPerPage = (type) => {
    if (type === 'journeys') {
      return isMobile ? 2 : 4;
    }
    return isMobile ? 2 : 6;
  };

  const sortContent = (list) => {
    if (!list?.length) {
      return [];
    }
    const copy = [...list];
    switch (order) {
      case 'new':
        return copy.sort((j1, j2) =>
          j1?.data?.startDate < j2?.data?.startDate ? 1 : -1,
        );
      case 'old':
        return copy.sort((j1, j2) =>
          j1?.data?.startDate < j2?.data?.startDate ? -1 : 1,
        );
      case 'z':
        return copy.sort((j1, j2) =>
          j1?.data?.title?.toUpperCase() < j2?.data?.title?.toUpperCase()
            ? 1
            : -1,
        );
      case 'a':
      default:
        return copy.sort((j1, j2) =>
          j1?.data?.title?.toUpperCase() > j2?.data?.title?.toUpperCase()
            ? 1
            : -1,
        );
    }
  };

  const transformItems = (items) => {
    if (!items?.length) {
      return items;
    }

    let transformed = sortContent(items);
    transformed = transformed.map((item) => {
      const newItem = item;
      const id = item.data?.path;
      const n = id.lastIndexOf('/');
      newItem.data['@name'] = id.substring(n + 1);
      return newItem;
    });

    return transformed;
  };

  function ArticleHits({ start = 0, num = 2, empty = false }) {
    const { hits } = useHits({ transformItems });
    setHitNumArticle(hits.length);
    if (empty) {
      return <></>;
    }
    return hits.splice(start, num).map((hit) => {
      const article = hit.data;
      article['mgnl:tags'] = article.topics || article['mgnl:tags'];
      return (
        <ArticleCard
          fetchedArticle={article}
          isSearchResult={true}
          key={hit.data?.path}
          theme={theme}
        />
      );
    });
  } // NOSONAR

  const transformJourneys = (items) => {
    if (!items?.length) {
      return items;
    }
    const transformed = sortContent(items);
    return transformed;
  };

  function JourneyHits({ start = 0, num = 2, empty = false }) {
    const { hits } = useHits({ transformItems: transformJourneys });
    setHitNumJourney(hits.length);
    if (empty) {
      return <></>;
    }

    return hits.splice(start, num).map((hit) => {
      const journey = hit.data;
      journey['mgnl:tags'] = journey.topics || journey['mgnl:tags'];
      return (
        <ImageWithParts
          isListing={true}
          journeyData={journey}
          key={hit?.data?.title}
        />
      );
    });
  } // NOSONAR

  const getPages = (type) => {
    const Tag = type === 'journeys' ? JourneyHits : ArticleHits;
    const hitNum = type === 'journeys' ? hitNumJourney : hitNumArticle;
    let n = hitNum;
    const slides = [];
    while (n > 0) {
      const toTake = Math.min(n, getNumPerPage(type));
      slides.push(<Tag num={toTake} start={hitNum - n} />);
      n -= toTake;
    }
    const pages = slides.map((s, i) => (
      <div className="contents" key={`page-${i}`}>
        {s}
      </div>
    ));
    return pages;
  };

  const setActiveTag = (tag) => {
    if (getActiveTag(tag)) {
      setFilter(tag !== filter ? tag : '');
    }
    articleSliderRef.slickGoTo(0, true);
    journeySliderRef.slickGoTo(0, true);
  };

  const getMaxPage = (type) => {
    const n = type === 'journeys' ? hitNumJourney : hitNumArticle;
    return Math.ceil(n / getNumPerPage(type)) || 1;
  };

  /**
   * Convenience function to trigger callSegmentTrack.
   *
   * @param {object} params - The function params object.
   * @param {string} [params.action] - Optional value for action.
   * @param {string} [params.event] - Optional event associated with the tracking request.
   * @param {string} [params.label] - Optional value for label.
   * @param {string} [params.value] - Optional value associated with the tracking request.
   */
  function callAnalytics({ action, event, label, value }) {
    callSegmentTrack({
      event: event || EVENTS.buttonAction,
      properties: {
        action: action || ACTIONS.clicked,
        component: 'Topics Listing',
        component_url: null, // Inner-component scroll, no component URL.
        label,
        logged_in: !!user,
        preferred_campus: null,
        referrer: document?.referrer || null,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
        value,
      },
    });
  }

  const back = (ref, topRef) => {
    callAnalytics({ label: 'Back' });
    ref.slickPrev();
    setTimeout(() => {
      scrollToSection(topRef);
    }, 300);
  };

  const next = (ref, topRef) => {
    callAnalytics({ label: 'Next' });
    ref.slickNext();
    setTimeout(() => {
      scrollToSection(topRef);
    }, 300);
  };

  /**
   * Handler function for Next button click.
   *
   * @param {string} filterItem - The filter value.
   */
  function handleTagClick(filterItem) {
    callAnalytics({ label: filterItem });
    setActiveTag(filterItem);
  }

  /**
   * Handler function for filter change event.
   *
   * @param {Event} event - The Event object associated with the action.
   */
  function handleFilterChange(event) {
    callAnalytics({
      action: ACTIONS.changed,
      event: EVENTS.buttonAction,
      label: event.target.value,
    });
  }

  /**
   * Handler function for reset event.
   */
  function handleReset() {
    callAnalytics({ label: 'Reset' });
    if (childRef.current) {
      childRef.current.handleReset();
    }
  }

  return (
    <div className={`topics-listing-wrapper ${theme}`}>
      {title ? <h1 className="topic-title">{title}</h1> : null}
      <InstantSearch
        future={{
          preserveSharedStateOnUnmount: true,
        }}
        indexName={process.env.FINDS_ALGOLIA_INDEX_JOURNEYS}
        searchClient={searchClient}
      >
        <Configure filters={filter ? `data.topics:${filter}` : ''} />
        <div className="filters">
          <div className="left-filters">
            <FindsSearchBox
              ref={childRef}
              setSearchInput={setSearchInput}
              theme={theme}
            />
            <ul>
              {tagFilters.map((f) => (
                <li
                  className={f === filter ? 'active-tag' : ''}
                  key={f}
                  onClick={() => handleTagClick(f)}
                >
                  {f}
                </li>
              ))}
            </ul>
          </div>
          <FilterSelect
            onChange={(event) => {
              handleFilterChange(event);
              setOrder(event.target.value);
            }}
            theme={theme}
          />
        </div>
        {searchInput?.length !== 0 ? (
          <SearchResultsText
            onClearResults={handleReset}
            resultCount={hitNumArticle + hitNumJourney}
            searchText={searchInput}
          />
        ) : null}
        {hitNumJourney ? (
          <h2 className="listing-title" ref={journeyTopRef}>
            Journeys
          </h2>
        ) : null}
        <JourneyHits empty={true} />
        <Slider
          {...settingsJourney}
          draggable={getMaxPage('journeys') > 1}
          ref={(slider) => {
            journeySliderRef = slider;
          }}
        >
          {getPages('journeys')}
        </Slider>
        {hitNumJourney ? (
          <div className="pagination">
            <ArrowButton
              alt="Back Icon"
              className="icon"
              onClick={() => back(journeySliderRef, journeyTopRef)}
              orientation="left"
              theme={theme}
            />
            <div className="counter">
              {pageJourneys + 1} / {getMaxPage('journeys')}
            </div>
            <ArrowButton
              alt="Next Icon"
              className="icon"
              onClick={() => next(journeySliderRef, journeyTopRef)}
              orientation="right"
              theme={theme}
            />
          </div>
        ) : null}
        {hitNumArticle ? (
          <h2 className="listing-title" ref={articleTopRef}>
            Articles
          </h2>
        ) : null}
        <Index indexName={process.env.FINDS_ALGOLIA_INDEX_ARTICLES}>
          <ArticleHits empty={true} />
          <Slider
            {...settingsArticle}
            draggable={getMaxPage('articles') > 1}
            ref={(slider) => {
              articleSliderRef = slider;
            }}
          >
            {getPages('articles')}
          </Slider>
        </Index>
        {hitNumArticle ? (
          <div className="pagination">
            <ArrowButton
              alt="Back Icon"
              className="icon"
              onClick={() => back(articleSliderRef, articleTopRef)}
              orientation="left"
              theme={theme}
            />
            <div className="counter">
              {pageArticles + 1} / {getMaxPage('articles')}
            </div>
            <ArrowButton
              alt="Next Icon"
              className="icon"
              onClick={() => next(articleSliderRef, articleTopRef)}
              orientation="right"
              theme={theme}
            />
          </div>
        ) : null}
      </InstantSearch>
    </div>
  );
};

export default TopicsListing;
