/**
 * @module PageLoader
 */
import React from 'react';
import { Log } from '@io/web-tools-io/dist/utils/helpers/browserLogger';
import { getAPIBase } from '@io/web-tools-io/dist/utils/helpers/magnolia/getAPIBase';
import { convertS3DamItems } from '@io/web-tools-io/dist/utils/helpers/magnolia/imgix';
import {
  getCurrentLanguage,
  getLanguages,
  removeCurrentLanguage,
} from '@io/web-tools-io/dist/utils/helpers/magnolia/languages';
import { EditablePage, EditorContextHelper } from '@magnolia/react-editor';
import config from '../magnolia.config';
import { MGNL_ENV_VARS } from './constants';

class PageLoader extends React.Component {
  state = {};

  getPagePath = (apiBase) => {
    const languages = getLanguages(MGNL_ENV_VARS);
    const nodeName = process.env.REACT_APP_MGNL_APP_BASE;
    const currentLanguage = getCurrentLanguage(MGNL_ENV_VARS);

    let path =
      nodeName +
      window.location.pathname
        .replace(/(.html)/g, '')
        .replace('/magnoliaAuthor', '');
    if (currentLanguage !== languages[0]) {
      path = removeCurrentLanguage(path, currentLanguage);
      path += '?lang=' + currentLanguage;
    }
    if (path.startsWith('//')) {
      path = path.substring(1);
    }

    if (path.startsWith('/finds/')) {
      path = path.substring(6);
      Log.info(`PageLoader > pagePath: ${path}`);
      path =  apiBase + process.env.REACT_APP_MGNL_API_PAGES_FINDS + path;
    } else if (path.startsWith('/finds-articles/')) {
        path = path.substring(15);
        Log.info(`PageLoader > pagePath: ${path}`);
        path =  apiBase + process.env.REACT_APP_MGNL_API_ARTICLES_FINDS + path;
    } else if (path.startsWith('/finds-hubs/')){
        path = path.substring(11);
        Log.info(`PageLoader > pagePath: ${path}`);
        path =  apiBase + process.env.REACT_APP_MGNL_API_HUBS_FINDS + path;
    } else {
        Log.info(`PageLoader > pagePath: ${path}`);
        path =  apiBase + process.env.REACT_APP_MGNL_API_PAGES_FINDS + path; 
    }

    return path;
  };

  loadPage = async () => {
    // Bail out if already loaded content.
    if (this.state.pathname === window.location.pathname) return;
    const apiBase = getAPIBase(MGNL_ENV_VARS);
    let fullContentPath = this.getPagePath(apiBase)
    let pageResponse;
    let pageJson;
    // check if ___appProps exists
    if (!!window && !!window.___appProps && !!window.___appProps.currentPage) {
      pageJson = window.___appProps.currentPage;
    } else {
      try {
        pageResponse = await fetch(fullContentPath);
        pageJson = await pageResponse.json();
      } catch (error) {
        Log.error(error);
      }
    }

    const templateId = pageJson['mgnl:template'];

    let templateJson = null;
    if (EditorContextHelper.inIframe()) {
      try {
        const templateResponse = await fetch(
          apiBase + process.env.REACT_APP_MGNL_API_TEMPLATES + '/' + templateId,
        );
        templateJson = await templateResponse.json();
      } catch (error) {
        Log.error(error);
      }
    }
    this.setState({
      init: true,
      content: convertS3DamItems(pageJson, process.env.IMGIX_BASE_URL),
      templateDefinitions: templateJson,
      pathname: window.location.pathname,
    });
  };

  componentDidMount() {
    this.loadPage(); // NOSONAR
  }

  componentDidUpdate() {
    this.loadPage(); // NOSONAR
  }

  render() {
    if (this.state.init) {
      return (
        <EditablePage
          templateDefinitions={this.state.templateDefinitions || {}}
          content={this.state.content}
          config={config}
        ></EditablePage>
      );
    } else {
      return <p>NO PAGE.</p>;
    }
  }
}

export default PageLoader;
