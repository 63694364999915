/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/**
 * @module MenuModal
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { callSegmentTrack } from '@io/web-tools-io/dist/utils/helpers/analytics';
import useAuth from '@io/web-tools-io/dist/hooks/useAuth';
import { ACTIONS, EVENTS } from '../../helpers/constants';
import MenuModalLink from './MenuModalLink';
import './MenuModal.scss';

const MenuModal = (props) => {
  const { user } = useAuth();

  /**
   * Handler function for button or link item element click.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  function handleElementClick(event) {
    callSegmentTrack({
      event: EVENTS.buttonAction,
      properties: {
        action: ACTIONS.clicked,
        component: 'Menu Modal',
        component_url: event?.currentTarget?.getAttribute('href'),
        label: event?.currentTarget?.textContent,
        logged_in: !!user,
        preferred_campus: null,
        referrer: document?.referrer || null,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
  }

  /**
   * Handler function for menu item click.
   *
   * Within the handler, if `onMenuItemSelect` prop is present and a function,
   * invoke it and pass in the function to call from it, which in this case, is
   * the function to call Segment track.
   *
   * Note: The ignore directive added since all present elements use 'a'. The
   * logic here exists from the duplication of the component from LC Site V3.
   * However, test coverage does cover the cases for inclusion and exclusion of
   * the props.onMenuItemSelect and its functionality.
   *
   * @param {Event} event - The Event object associated with the click event.
   */
  /* istanbul ignore next */
  function handleMenuItemClick(event) {
    if (
      props.onMenuItemSelect &&
      typeof props.onMenuItemSelect === 'function'
    ) {
      const anchorElements =
        event?.currentTarget?.getElementsByTagName('a') || [];
      if (anchorElements.length) {
        const spanElements =
          anchorElements[0].getElementsByClassName('modal-link-title') || [];
        if (spanElements.length) {
          props.onMenuItemSelect({
            func: handleElementClick(event), // NOSONAR
          });
        }
      }
    }
  }
  const isContentNode = (menuItem) => menuItem['@nodeType'] === 'mgnl:content';

  return (
    <section className="menu-modal">
      <ul data-testid="menu-modal-list-container">
        {props.rootMenu
          ? props.rootMenu.map((section) => (
              <li
                data-testid="lc-menu-modal-root-menu-section"
                key={section['@id']}
                onClick={handleMenuItemClick}
              >
                <div className="modal-navigation" key={section['@id']}>
                  <MenuModalLink menuItem={section} />
                  <ul data-testid="lc-menu-modal-nodes-container">
                    {section['@nodes']
                      ? section['@nodes']
                          .filter((n) => isContentNode(section[n]))
                          .map((itemLinkNodeName) => {
                            const itemLink = section[itemLinkNodeName];
                            return (
                              <li
                                key={itemLink['@id']}
                                onClick={handleMenuItemClick}
                              >
                                <MenuModalLink menuItem={itemLink} />
                              </li>
                            );
                          })
                      : null}
                  </ul>
                </div>
              </li>
            ))
          : null}
      </ul>
    </section>
  );
};

export default MenuModal;
