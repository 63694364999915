/**
 * @module CardWithTags
 */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { callSegmentTrack } from '@io/web-tools-io/dist/utils/helpers/analytics';
import useAuth from '@io/web-tools-io/dist/hooks/useAuth';
import useWindowSize from '@io/web-tools-io/dist/hooks/useWindowSize';
import LCImage from '../LCImage/LCImage';
import Tags from '../Tags/Tags';
import { ACTIONS, EVENTS } from '../../helpers/constants';
import { getAppliedTheme } from '../../helpers/getAppliedTheme';
import useTheme from '../../hooks/useTheme';
import './CardWithTags.scss';

/**
 * Represents a container which contains an image, set of tags, and article/journey data (title, description, author).
 *
 * @param {object} props - The component props object.
 * @param {string} [props.author] - The author name for the specified journey/article.
 * @param {string} [props.description] - The description for the specified journey/article.
 * @param {string} [props.duration] - Optional duration value for the specified journey/article.
 * @param {boolean} [props.hasFullWidthImage] - Boolean flag indicating whether or not the component has a full-width image (Default: false).
 * @param {object|string} [props.image] - Optional image (string or data object) for the specified journey/article.
 * @param {boolean} [props.isArticle] - Boolean flag indicating whether or not the component data is for an article (Default: false).
 * @param {boolean} [props.isSearchResult] - Boolean flag indicating whether or not the component is being rendered in the context of search results (Default: false).
 * @param {string} [props.link] - Optional link value used as the target of the anchor tag link.
 * @param {object} [props.metadata] - Data object of journey/article metadata.
 * @param {Array} [props.tags] - Optional array of tags associated with the article (Default: []).
 * @param {string} props.theme - The theme value.
 * @param {string} [props.title] - The title for the specified journey/article.
 *
 * @returns {React.ReactElement} The CardWithTags component.
 */
const CardWithTags = ({
  author,
  description,
  duration,
  hasFullWidthImage,
  image,
  isArticle,
  isSearchResult,
  link,
  metadata,
  tags,
  theme,
  title,
}) => {
  const { user } = useAuth();
  const { width } = useWindowSize();
  const { pageTheme } = useTheme();
  const appliedTheme = getAppliedTheme(pageTheme, theme);
  const usedTags = tags || metadata?.['mgnl:tags'] || [];

  const handleOnClick = (event) => {
    callSegmentTrack({
      event: EVENTS.buttonAction,
      properties: {
        action: ACTIONS.clicked,
        component: 'Card with Tags',
        component_url:
          event.target.tagName.toLowerCase() !== 'a' && link
            ? link
            : event?.currentTarget?.getAttribute('href'),
        label: title,
        logged_in: !!user,
        preferred_campus: null,
        referrer: document?.referrer || null,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });

    if (event.target.tagName.toLowerCase() !== 'a' && link) {
      window.location.href = link;
    }
  };

  const taggedImageClassName = hasFullWidthImage
    ? 'full-width-tagged-image narrow'
    : 'tagged-image';
  const wrapperImageClassName = hasFullWidthImage
    ? 'full-width-image-wrapper'
    : 'image-wrapper';

  let titleClassName = 'tagged-image-title';
  if (isSearchResult) {
    titleClassName = 'search-result-title';
  } else if (isArticle) {
    titleClassName = 'article-title';
  }

  const descriptionClassName = isSearchResult
    ? 'search-result-text'
    : 'tagged-image-text';

  return (
    <div
      className={`card-wrapper${link ? ' clickable-card' : ''}`}
      data-testid="lc-card-with-tags"
      draggable={false}
      onClick={handleOnClick}
    >
      <div className={`tagged-image-wrapper container ${appliedTheme}`}>
        <div className={taggedImageClassName}>
          <div className={wrapperImageClassName}>
            <LCImage
              className="image"
              src={image?.['@link'] || image || ''}
              width={hasFullWidthImage ? width : 325}
            />
          </div>
          <div
            className={`content-wrapper ${
              isSearchResult ? 'search-result' : ''
            }`.trim()}
          >
            <Tags tags={usedTags} />
            <h2 className={`card-title ${titleClassName}`.trim()}>
              <span href={link}>{title}</span>
            </h2>
            {description && !isSearchResult ? (
              <p className={descriptionClassName}>{description}</p>
            ) : null}
            {isArticle && author ? (
              <p className="card-author-duration">
                {[author, duration].filter(Boolean).join(' • ')}
              </p>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardWithTags;
