/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/**
 * @module Modal
 */
import React from 'react';
import { EditorContextHelper } from '@magnolia/react-editor';
import { callSegmentTrack } from '@io/web-tools-io/dist/utils/helpers/analytics';
import { Log } from '@io/web-tools-io/dist/utils/helpers/browserLogger';
import { convertValueToClassName } from '@io/web-tools-io/dist/utils/helpers/validators';
import { getAPIBase } from '@io/web-tools-io/dist/utils/helpers/magnolia/getAPIBase';
import { addParamsToUrl } from '@io/web-tools-io/dist/utils/helpers/magnolia/urls';
import useAuth from '@io/web-tools-io/dist/hooks/useAuth';
import useWindowSize from '@io/web-tools-io/dist/hooks/useWindowSize';
import RichText from '../RichText/RichText';
import Button from '../ButtonItem/ButtonItem';
import YoutubeVideo from '../YoutubeVideo/YoutubeVideo';
import Hubspot from '../EmbedItem/Hubspot/Hubspot';
import FormStack from '../EmbedItem/FormStack/FormStack';
import { ACTIONS, EVENTS, MGNL_ENV_VARS } from '../../helpers/constants';
import LCBackground from '../LCImage/LCBackground';
import './Modal.scss';

const Modal = ({
  children = null,
  className = '',
  data = null,
  id,
  modalTrigger,
  setShowStatus,
  showStatus,
}) => {
  const { user } = useAuth();
  const [modalData, setModalData] = React.useState({});
  const [innerHeight, setInnerHeight] = React.useState('100vh');
  const modalRef = React.useRef(null);
  const API_ENDPOINT = `${getAPIBase(MGNL_ENV_VARS)}/.rest/delivery/modal`;
  const isDevMode = EditorContextHelper.inIframe();

  const containerClass = `modal-wrapper ${
    showStatus ? 'show' : 'show inactive'
  } ${convertValueToClassName(
    modalData?.modalType?.field,
  )} ${convertValueToClassName(className)} ${
    isDevMode ? 'modal-dev-mode' : ''
  }`.trim();

  const handleCloseModal = () => {
    /* istanbul ignore next */
    callSegmentTrack({
      event: EVENTS.buttonAction,
      properties: {
        action: ACTIONS.clicked,
        component: 'Modal',
        component_url: null, // No URL, as this is just for modal close.
        label: 'Close',
        logged_in: !!user,
        preferred_campus: null,
        referrer: document?.referrer || null,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });

    // Currently, all video modals are youtube videos, iframe. As long as video modals are iframe, this code block is valid for stopping video from playing when modal is closed.
    if (
      modalData?.modalType?.field === 'videoModal' &&
      !!modalData?.modalType?.youtubeID
    ) {
      const iframe = document.querySelector(
        `#ytid-${modalData.modalType.youtubeID}`,
      );
      /* istanbul ignore next */
      if (iframe) {
        const iframeSrc = iframe.src;
        iframe.src = iframeSrc;
      }
    }
    /* istanbul ignore next */
    if (setShowStatus && typeof setShowStatus === 'function') {
      setShowStatus(false);
    }
    document.body.style.overflow = '';
    // eslint-disable-next-line no-use-before-define
    document.removeEventListener('click', handleOutsideClick, false);
  };

  const handleOutsideClick = (e) => {
    if (!!modalRef && !!modalRef?.current) {
      /* istanbul ignore next */
      if (!modalRef.current.contains(e.target)) {
        handleCloseModal();
      }
    }
  };

  const handleResize = () => {
    setInnerHeight(`${window.innerHeight}px`);
  };

  React.useEffect(() => {
    async function fetchModalList() {
      if (modalTrigger) {
        try {
          const response = await fetch(
            `${API_ENDPOINT}?@jcr:uuid=${modalTrigger}`,
          );
          const modalListData = await response.json();
          const tempData = !!modalListData && modalListData.results[0];
          setModalData(tempData);
        } catch (error) {
          /* istanbul ignore next */
          Log.error(error);
        }
      }
    }
    handleResize();
    window.addEventListener('resize', handleResize);
    if (data) {
      setModalData(data);
    } else {
      fetchModalList(); // NOSONAR
    }
    return () => window.removeEventListener('resize', handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (showStatus) {
      document.body.style.overflow = 'hidden';
      document.addEventListener('click', handleOutsideClick, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showStatus]);

  return (
    <div
      className={containerClass}
      data-testid="lc-modal"
      id={id}
      style={{ maxHeight: innerHeight }}
    >
      <div className="close-wrapper display-flex justify-flex-end align-center">
        <span
          className="close-button mr-normal"
          data-testid="modal-close-button"
          onClick={handleCloseModal}
        >
          ✕
        </span>
      </div>
      <div
        className="modal border-radius-s"
        ref={modalRef}
        style={{
          maxHeight: `calc(${innerHeight} - 64px)`,
          overflowY: 'scroll',
        }}
      >
        {!children && !!modalData && !!modalData.modalType ? (
          <div className="modal-content">
            {modalData.modalType.field === 'videoModal' ? (
              <VideoModalContent data={modalData.modalType} />
            ) : null}
            {modalData.modalType.field === 'formModal' ? (
              <FormModalContent data={modalData.modalType} />
            ) : null}
            {modalData.modalType.field === 'infoModal' ? (
              <InfoModalContent data={modalData.modalType} />
            ) : null}
          </div>
        ) : null}
        {!!children && <div className="modal-content">{children}</div>}
      </div>
    </div>
  );
};
export default Modal;

const VideoModalContent = ({ data }) => {
  return (
    <>{data.youtubeID ? <YoutubeVideo youtubeID={data.youtubeID} /> : null}</>
  );
};

const FormModalContent = ({ data }) => {
  const { isMobile } = useWindowSize();
  const urlParams = !!data.type && encodeURI(data.type.urlParams);
  const iFrameId = `form-modal-iframe-${data?.type?.['@id']}`;

  /**
   * Handler function for resize message event.
   *
   * Note: The conditionals used ensure that:
   * 1. The event.origin matches a Life.Church origin.
   * 2. The iframe exists before getting and setting its attributes.
   * 3. The iframe source matches and event has `height` attribute.
   *
   * The logic here are derived from IFrame.js that is used in EmbedItem. It is
   * an intentional decision to use the existing iframe logic here without using
   * the IFrame component, so as to ensure no break in functionality or risk
   * unintended differences in rendering or UI/UX.
   *
   * @param {Event} event - The Event object associated with the message.
   */
  function resizeIframeHeight(event) {
    if (event.origin.match('life.church')) {
      const iFrame = document.getElementById(iFrameId);
      const message = event.data;
      /**
       * Ensure the iframe exists before getting and setting its attributes.
       * Note: Ignore directive added because iframe will be found in the tests
       * due to how the data is set up and component rendered. All functionality
       * within this conditional is tested for completeness.
       */
      /* istanbul ignore next */
      if (iFrame) {
        const iFrameSrc = new URL(iFrame.src);

        /**
         * Note: Only want to change height for our own hosted sources (such as
         * Rock forms, not any/all other iframe sources.
         */
        const lcRegExp = /life.church/g;
        if (message?.height && lcRegExp.exec(iFrameSrc.host)) {
          iFrame.height = `${parseInt(message.height, 10)}px`;
        }
      }
    }
  }

  /**
   * Single-run convenience effect to add `message` listener for iframe events.
   */
  React.useEffect(() => {
    window.addEventListener('message', resizeIframeHeight);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hasSetHeight = () => {
    return isMobile ? data?.type?.heightMobile : data?.type?.heightDesktop;
  };

  const getHeight = () => {
    return isMobile
      ? `${data?.type?.heightMobile}px`
      : `${data?.type?.heightDesktop}px`;
  };

  if (!!urlParams && urlParams !== 'undefined') {
    if (
      !!data.type.iframeSource &&
      data.type.iframeSource.indexOf(urlParams) < 0
    ) {
      // eslint-disable-next-line no-param-reassign
      data.type.iframeSource = addParamsToUrl(
        data.type.iframeSource,
        urlParams,
      );
    } else if (window.location.search.indexOf(urlParams) < 0) {
      const newurl = addParamsToUrl(
        `${window.location.protocol}//${window.location.host}${window.location.pathname}${window.location.search}`,
        urlParams,
      );
      window.history.pushState({ path: newurl }, '', newurl);
    }
  }

  return (
    <>
      {data?.type?.field === 'hubspot' && data.type.hubspotFormID ? (
        <div className="embed-item">
          <Hubspot hubspotFormID={data.type.hubspotFormID} />
        </div>
      ) : null}
      {data?.type?.field === 'formstack' && data.type.formstackFormSlug ? (
        <FormStack formstackFormSlug={data.type.formstackFormSlug} />
      ) : null}
      {data?.type?.field === 'iframe' && data.type.iframeSource ? (
        <iframe
          className="iframe-form"
          frameBorder="0"
          height={hasSetHeight() ? getHeight() : undefined}
          id={iFrameId}
          scrolling="yes"
          src={data.type.iframeSource}
          title="iframe-form"
          width="100%"
        >
          {' '}
        </iframe>
      ) : null}
    </>
  );
};

const InfoModalContent = ({ data }) => {
  const { user } = useAuth();
  const bgImage = data?.featuredImage?.['@link'] ?? data?.featuredImage ?? '';

  /**
   * Handler function for button or link item element click.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  function handleElementClick(event) {
    /* istanbul ignore next */
    callSegmentTrack({
      event: EVENTS.buttonAction,
      properties: {
        action: ACTIONS.clicked,
        component: 'Info Modal',
        component_url: event?.currentTarget?.getAttribute('href'),
        label: event?.currentTarget?.textContent,
        logged_in: !!user,
        preferred_campus: null,
        referrer: document?.referrer || null,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
  }

  return (
    <>
      {
        /* istanbul ignore next */ !!data.featuredImage && (
          <LCBackground className="featured-image" src={bgImage}></LCBackground>
        )
      }
      {!!data.heading && <h2 className="modal-heading">{data.heading}</h2>}
      {!!data.subHeading && (
        <p className="modal-sub-heading text-paragraph_large text-light-grayscale">
          {data.subHeading}
        </p>
      )}
      {!!data.content && (
        <RichText
          content={data.content}
          sbOnMobile="mb-relaxed"
          sbOnTabletAndUp="mb-relaxed"
        />
      )}
      {!!data.button && (
        <div
          className={`modal-button button-align-${
            convertValueToClassName(data.buttonAlign) || 'left'
          }`}
        >
          {data.button['@nodes'].map((k) => (
            <Button
              key={data.button[k]['@id']}
              onClick={handleElementClick}
              style={data.button[k].buttonStyle}
              target={data.button[k].target}
              text={data.button[k].buttonTitle}
              url={data.button[k].buttonUrl}
            />
          ))}
        </div>
      )}
    </>
  );
};
