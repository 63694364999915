/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/**
 * @module AncherNavigation
 */
import React from 'react';
import { callSegmentTrack } from '@io/web-tools-io/dist/utils/helpers/analytics';
import { convertValueToClassName } from '@io/web-tools-io/dist/utils/helpers/validators';
import useAuth from '@io/web-tools-io/dist/hooks/useAuth';
import useWindowSize from '@io/web-tools-io/dist/hooks/useWindowSize';
import { ACTIONS, COLOR_THEMES, EVENTS } from '../../helpers/constants';
import { getAppliedTheme } from '../../helpers/getAppliedTheme';
import useTheme from '../../hooks/useTheme';
import './AncherNavigation.scss';

/**
 * Represents an arrow at a down angle.
 *
 * @param {object} props - The component props object.
 * @param {string} [props.color] - The hex color to apply to the icon.
 *
 * @returns {React.ReactElement} The Angle arrow icon.
 */
function Angle({ color }) {
  return (
    <svg
      fill="none"
      height="4"
      viewBox="0 0 8 4"
      width="8"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8 0H0L3.80952 4L8 0Z" fill={color} />
    </svg>
  );
}

const AncherNavigation = (props) => {
  const { ancherButton, sbOnMobile, sbOnTabletAndUp, theme } = props;
  const { user } = useAuth();
  const { pageTheme } = useTheme();
  const appliedTheme = getAppliedTheme(pageTheme, theme);
  const { isMobile } = useWindowSize();
  const [activeTitle, setActiveTitle] = React.useState(null);
  const [mbActive, setMbActive] = React.useState(null);
  const [urlBase, setUrlBase] = React.useState('');

  const sectionClass = `ancher-navigation text-center ${appliedTheme} ${
    isMobile
      ? convertValueToClassName(sbOnMobile)
      : convertValueToClassName(sbOnTabletAndUp)
  }`.trim();
  const ulClass = `nav-list ${mbActive ? 'active' : ''}`.trim();

  React.useEffect(() => {
    if (ancherButton?.['@nodes']?.length) {
      setActiveTitle(ancherButton[ancherButton['@nodes'][0]].ancherTitle);
    }
    setUrlBase(window.location.href.split('#')[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Handler function for mobile active list item click.
   *
   * @param {string} title - The title of the element.
   * @param {Event} event - The Event object associated with the click.
   */
  const handleMobileActive = (title, event) => {
    callSegmentTrack({
      event: EVENTS.buttonAction,
      properties: {
        action: ACTIONS.clicked,
        component: 'Anchor Navigation',
        component_url: event?.currentTarget?.getAttribute('href'),
        label: event?.currentTarget?.textContent,
        logged_in: !!user,
        preferred_campus: null,
        referrer: document?.referrer || null,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
    setMbActive(!mbActive);
    setActiveTitle(title);
  };

  return (
    <section className={sectionClass} data-testid="lc-anchor-navigation">
      <h3
        className="title mobile-active"
        onClick={() => setMbActive(!mbActive)}
      >
        {activeTitle}
        <Angle
          color={
            appliedTheme === COLOR_THEMES['light-mode'] ? '#000000' : '#ffffff'
          }
        />
      </h3>
      <ul className={ulClass}>
        {ancherButton?.['@nodes']?.length
          ? ancherButton['@nodes'].map((ancher) => {
              let url = '#';
              if (ancherButton[ancher].ancherUrl) {
                url =
                  ancherButton[ancher].ancherUrl.charAt(0) === '#'
                    ? urlBase + ancherButton[ancher].ancherUrl
                    : ancherButton[ancher].ancherUrl;
              }

              return (
                <li key={ancherButton[ancher]['@id']}>
                  <a
                    className={`title ${
                      activeTitle === ancherButton[ancher].ancherTitle
                        ? 'active'
                        : ''
                    }`}
                    href={url}
                    onClick={(event) => {
                      handleMobileActive(
                        ancherButton[ancher].ancherTitle,
                        event,
                      );
                    }}
                    target={ancherButton[ancher].target}
                  >
                    {ancherButton[ancher].ancherTitle}
                  </a>
                </li>
              );
            })
          : null}
      </ul>
    </section>
  );
};

export default AncherNavigation;
