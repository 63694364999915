/**
 * @module TextImageCTA
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import useWindowSize from '@io/web-tools-io/dist/hooks/useWindowSize';
import ButtonItem from '../ButtonItem/ButtonItem';
import LCImage from '../LCImage/LCImage';
import { getAppliedTheme } from '../../helpers/getAppliedTheme';
import useTheme from '../../hooks/useTheme';
import './TextImageCTA.scss';

/**
 * Represents a container with a background image and title and button item for the specified data.
 *
 * @param {object} props - The component props object.
 * @param {string} [props.backgroundImage] - Optional background image src.
 * @param {object} [props.link] - Optional object of link data.
 * @param {string} props.onClick - Handler function for button click event.
 * @param {string} props.theme - The theme value.
 * @param {string} props.title - The title for the call to action.
 *
 * @returns {React.ReactElement} The TextImageCTA component.
 */
const TextImageCTA = ({ backgroundImage, link, onClick, theme, title }) => {
  const { linkTitle = 'Read More', target = '_self', url = '#' } = link || {};
  const { width } = useWindowSize();
  const { pageTheme } = useTheme();
  const appliedTheme = getAppliedTheme(pageTheme, theme);

  /**
   * Handler function for the button item click.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  function handleButtonClick(event) {
    if (onClick && typeof onClick === 'function') {
      onClick(event);
    }
  }

  return (
    <div
      className={`text-image-cta-wrapper ${appliedTheme}`}
      data-testid="lc-text-image-cta-wrapper"
    >
      <div className="text-image-cta-gradient"></div>
      <LCImage className="image" src={backgroundImage || ''} width={width} />
      <div className="content container">
        <div className="centered-content">
          <h1 className="title">{title}</h1>
          <ButtonItem
            className="button"
            data-testid="lc-text-image-cta-button"
            onClick={handleButtonClick}
            style="btn-primary"
            target={target}
            text={linkTitle}
            url={url}
          />
        </div>
      </div>
    </div>
  );
};

export default TextImageCTA;
