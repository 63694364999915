/**
 * @module Tags
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { callSegmentTrack } from '@io/web-tools-io/dist/utils/helpers/analytics';
import useAuth from '@io/web-tools-io/dist/hooks/useAuth';
import { ACTIONS, EVENTS } from '../../helpers/constants';
import './Tags.scss';

const Tags = ({ tags = [] }) => {
  const { user } = useAuth();

  /**
   * Handler function for tag click event.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  function handleTagClick(event) {
    callSegmentTrack({
      event: EVENTS.buttonAction,
      properties: {
        action: ACTIONS.clicked,
        component: 'Tag',
        component_url: event?.currentTarget?.getAttribute('href'),
        label: event?.currentTarget?.textContent,
        logged_in: !!user,
        preferred_campus: null,
        referrer: document?.referrer || null,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
  }

  return (
    <div className="tag-wrapper" data-testid="lc-tags">
      {tags.map((t) => (
        <a
          className="tag"
          href={`/topics/${t}`}
          key={t}
          onClick={handleTagClick}
        >
          <span>{t.replace(/-/g, ' ')}</span>
        </a>
      ))}
    </div>
  );
};

export default Tags;
