/**
 * @module Basic
 */
import React from 'react';
import { Log } from '@io/web-tools-io/dist/utils/helpers/browserLogger';
import { getQueryParam } from '@io/web-tools-io/dist/utils/helpers/queryParams';
import { EditableArea } from '@magnolia/react-editor';
import { getAPIBase } from '@io/web-tools-io/dist/utils/helpers/magnolia/getAPIBase';
import { MGNL_ENV_VARS } from '../helpers/constants';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import LCDevBar from '../components/LCDevBar';
import NavigationBar from '../components/NavigationBar/NavigationBar';
import Modal from '../components/Modal/Modal';
import useModals from '../hooks/useModals';
import useTheme from '../hooks/useTheme';
import fetchBasicTemplate from '../helpers/dataFetchers/basicTemplateFetcher';
import Search from '../components/Search/Search';
import Breadcrumbs from '../components/Breadcrumbs/Breadcrumbs';

const Basic = (props) => {
  const { modalTriggers, modalVisibilities, showModal } = useModals();
  const { storePageTheme } = useTheme();
  const [modal, setModal] = React.useState(null);
  const [searchOpen, setSearchOpen] = React.useState(false);
  const [data, setData] = React.useState(null);
  const { main, displayNavBar = false } = props;
  const navBar = data?.navBar;

  React.useEffect(() => {
    const fetchModalByHash = async () => {
      let { hash } = window.location;
      if (hash) {
        // eslint-disable-next-line prefer-destructuring
        hash = hash.split('#')[1];
        const apiBase = getAPIBase(MGNL_ENV_VARS);
        await fetch(`${apiBase}/.rest/delivery/modal?@name=${hash}`)
          .then((res) => res.json())
          .then((res) => {
            if (!!res.results && !!res.results.length) {
              setModal(res.results[0]);
            }
          })
          // eslint-disable-next-line no-console
          .catch((err) => console.log({ err }));
      }
    };

    async function fetchData() {
      try {
        const theme = getQueryParam('theme');
        const basicTemplateData = await fetchBasicTemplate(props, theme);
        setData(basicTemplateData);
      } catch (error) {
        Log.error(error);
      }
    }
    if (props.preload) {
      setData(props.preload);
    } else {
      fetchData(); // NOSONAR
    }

    fetchModalByHash(); // NOSONAR
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (props?.backgroundColor) {
      storePageTheme(
        props.backgroundColor === 'cream' ? 'light-mode' : 'dark-mode',
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const searchModalHandler = () => {
    setSearchOpen(!searchOpen);
  };

  return (
    <>
      {
        <div className="Basic">
          {data?.menuData ? (
            <Header
              menuData={data.menuData}
              menuItems={data.menuItems}
              navItems={data.navItems}
              searchModalHandler={searchModalHandler}
            />
          ) : null}
          <main
            className={
              props?.backgroundColor ? `bg-${props?.backgroundColor}` : ''
            }
          >
            {searchOpen && <Search searchModalHandler={searchModalHandler} />}
            {!!props.addBreadcrumbs && (
              <Breadcrumbs isLightMode={props?.backgroundColor === 'cream'} />
            )}
            {main ? (
              <EditableArea
                className={`Area ${searchOpen && 'hidden-content'}`}
                content={main}
              />
            ) : null}
            {modal ? (
              <Modal
                data={modal}
                setShowStatus={() => setModal(null)}
                showStatus={true}
              />
            ) : null}
            {modalTriggers
              ? Object.entries(modalTriggers).map(
                  ([modalTriggerId, modalTriggerData]) => {
                    return (
                      <Modal
                        data={modalTriggerData}
                        key={modalTriggerId}
                        setShowStatus={() => {
                          showModal(modalTriggerId, false);
                          window.history.pushState(
                            {},
                            null,
                            window.location.href.split('#')[0],
                          );
                        }}
                        showStatus={modalVisibilities[modalTriggerId]}
                      />
                    );
                  },
                )
              : null}
          </main>
          {data?.footerData ? <Footer footerData={data.footerData} /> : null}
          {process.env.NODE_ENV !== 'production' ? <LCDevBar /> : null}
        </div>
      }
    </>
  );
};

export default Basic;
