/**
 * @module EmbedItem
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { convertValueToClassName } from '@io/web-tools-io/dist/utils/helpers/validators';
import useWindowSize from '@io/web-tools-io/dist/hooks/useWindowSize';
import Hubspot from './Hubspot/Hubspot';
import FormStack from './FormStack/FormStack';
import IFrame from './IFrame/IFrame';
import { getAppliedTheme } from '../../helpers/getAppliedTheme';
import useTheme from '../../hooks/useTheme';
import './EmbedItem.scss';

const EmbedItem = (props) => {
  const { classes, id, sectionId, style, theme, type } = props;
  const backgroundColor = props?.backgroundColor || 'dark';
  const { pageTheme } = useTheme();
  const appliedTheme = getAppliedTheme(pageTheme, theme);

  const { isMobile } = useWindowSize();

  const contentTypes = {
    isFormstack: type?.field === 'formstack' && type?.formstackFormSlug,
    isHubspot: type?.field === 'hubspot' && type?.hubspotFormID,
    isIframe: type?.field === 'iframe' && type?.iframeSource,
  };

  const hubspotClass = type?.hubspotStyle
    ? convertValueToClassName(type?.hubspotStyle)
    : '';

  return (
    <section
      className={`embed-item border-radius-s container ${appliedTheme} bg-${backgroundColor} ${
        classes || ''
      } ${
        (contentTypes.isFormstack || contentTypes.isHubspot) && isMobile
          ? ' pl-none pr-none'
          : ''
      }`.trim()}
      data-testid="lc-embeditem"
      id={sectionId}
      style={style}
    >
      {!!type && type.field === 'hubspot' && !!type.hubspotFormID && (
        <Hubspot className={hubspotClass} hubspotFormID={type.hubspotFormID} />
      )}

      {!!type && type.field === 'formstack' && !!type.formstackFormSlug && (
        <FormStack formstackFormSlug={type.formstackFormSlug} />
      )}

      {!!type && type.field === 'iframe' && !!type.iframeSource ? (
        <IFrame id={id} isMobile={isMobile} sectionId={sectionId} type={type} />
      ) : null}
    </section>
  );
};

export default EmbedItem;
