/* eslint-disable jsx-a11y/media-has-caption */
/**
 * @module SingleContentZone
 */
import React from 'react';
import { EditableArea, EditorContextHelper } from '@magnolia/react-editor';
import { Log, convertToCmsAssetUrl } from '@io/web-tools-io/dist/utils/helpers';
import useWindowSize from '@io/web-tools-io/dist/hooks/useWindowSize';
import YoutubeVideo from '../YoutubeVideo/YoutubeVideo';
import LCBackground from '../LCImage/LCBackground';
import './SingleContentZone.scss';

const SingleContentZone = (props) => {
  const {
    backgroundImage,
    backgroundImageMobile,
    backgroundVideo,
    bgcolor,
    bgRepeat,
    colorsetting,
    gradientend,
    gradientstart,
    gradientType,
    icon,
    metadata,
    pyOnMobile = 'py-even_more_relaxed',
    pyOnTabletAndUp = 'py-even_more_relaxed',
    sbOnMobile,
    sbOnTabletAndUp,
    sectionId,
    youtubeID,
    zoneHeight,
    zones,
    zoneWidth = 'half',
  } = props;
  let { alignment } = props;

  const { isMobile, isTablet } = useWindowSize();
  const singleRef = React.useRef(null);
  const bgVideo = !!youtubeID || !!backgroundVideo;
  const bgImgDesktop = !bgVideo && !!backgroundImage ? backgroundImage : '';
  const bgImgMobile = backgroundImageMobile ?? backgroundImage ?? '';
  const [finalVideoBackground, setFinalVideoBackground] = React.useState();

  const isDevMode = EditorContextHelper.inIframe();

  const flexAlignments = {
    'text-center': 'center',
    'text-left': 'flex-start',
    'text-right': 'flex-end',
  };

  alignment = flexAlignments?.[alignment] ?? '';

  const iconClassName = !backgroundImage ? ` bg-${bgcolor} p-10` : '';

  /**
   * Convenience callback to determine final video URL based on conversion to
   * CMS Assets URL rather than Imgix.
   */
  const convertUrl = React.useCallback(async () => {
    try {
      const cmsAssetUrl = await convertToCmsAssetUrl({
        cmsAssetsBaseUrl: process.env.CMS_ASSETS_BASE_URL,
        imgixBaseUrl: process.env.IMGIX_BASE_URL,
        source: backgroundVideo,
      });
      setFinalVideoBackground(cmsAssetUrl);
    } catch (error) {
      /* istanbul ignore next */
      Log.error(error);
    }
  }, [backgroundVideo]);

  /**
   * Convenience effect to trigger url conversion to CMS Asset.
   */
  React.useEffect(() => {
    convertUrl();
  }, [backgroundVideo, convertUrl]);

  return (
    <section id={sectionId} ref={singleRef}>
      <LCBackground
        className={`single-contentzone position-relative bg-${bgcolor} ${
          bgRepeat === 'true' ? 'bg-repeat' : ''
        } ${zoneHeight === '70vh' ? 'height-70vh' : ''} ${
          !!icon && 'mt-56'
        } ${colorsetting} ${
          isMobile
            ? `${sbOnMobile} ${pyOnMobile}`
            : `${sbOnTabletAndUp} ${pyOnTabletAndUp}`
        }`}
        devmodeexception={isDevMode}
        imgixParams={bgRepeat === 'true' ? { ar: '1:1' } : ''}
        parentref={singleRef}
        src={isTablet || isMobile ? bgImgMobile : bgImgDesktop}
      >
        {finalVideoBackground && !youtubeID && (
          <video
            autoPlay="autoplay"
            className="video-background"
            loop={true}
            muted="muted"
            style={{ maxWidth: '100%' }}
          >
            <source src={finalVideoBackground} />
          </video>
        )}

        {!!youtubeID && (
          <div className="video-background" style={{ maxWidth: '100%' }}>
            <YoutubeVideo
              autoplay={true}
              hideInfo={true}
              youtubeID={youtubeID}
            />
          </div>
        )}

        {!!icon && (
          <div className={`singleIcon${iconClassName}`}>
            <img alt="single-icon" src={`${icon}`} />
          </div>
        )}
        <div
          className={`gradient-${gradientstart}-${gradientend}-${gradientType}`}
          style={{
            height: '100%',
            position: 'absolute',
            top: 0,
            width: '100%',
          }}
        ></div>
        <div
          className={`container justify-${alignment} ${zoneWidth}`}
          style={{ position: 'relative' }}
        >
          {!!zones && (
            <EditableArea
              content={zones}
              parentTemplateId={metadata['mgnl:template']}
            />
          )}
        </div>
      </LCBackground>
    </section>
  );
};

export default SingleContentZone;
