/**
 * @module VideoWithParts
 */
import React from 'react';
import { callSegmentTrack } from '@io/web-tools-io/dist/utils/helpers/analytics';
import useAuth from '@io/web-tools-io/dist/hooks/useAuth';
import { v4 as uuidv4 } from 'uuid';
import writingIcon from './writing-logo.svg';
import listeningIcon from './listening-logo.svg';
import watchingIcon from './watching-logo.svg';
import ButtonItem from '../ButtonItem/ButtonItem';
import LCBackground from '../LCImage/LCBackground';
import { ACTIONS, EVENTS } from '../../helpers/constants';
import { fetchJourneyPreview } from '../../helpers/dataFetchers/findsFetchers';
import { getAppliedTheme } from '../../helpers/getAppliedTheme';
import Video from '../Video/Video';
import useTheme from '../../hooks/useTheme';
import './VideoWithParts.scss';

/**
 * Represents a container holding video, icon, tag, and text elements.
 *
 * @param {object} props - The component props object.
 * @param {string} [props.background] - Optional background color value to apply as a `bg-` class name. If no value provided, it will default to a value of 'none'. (Example: 'gold' translates to applying `.bg-gold` as a class name value).
 * @param {string} props.journey - The journey uuid value.
 * @param {object} [props.journeyData] - Optional object of journey data.
 * @param {object} [props.preload] - Optional object of preloaded journey data.
 * @param {string} props.theme - The theme value.
 *
 * @returns {React.ReactElement} The ImageWithParts component.
 */
const VideoWithParts = ({
  background,
  journey,
  journeyData = false,
  preload,
  theme,
  ...passThroughProps
}) => {
  const { user } = useAuth();
  const { pageTheme } = useTheme();
  const [data, setData] = React.useState(
    journeyData || preload || passThroughProps?.data,
  );
  const appliedTheme = getAppliedTheme(pageTheme, theme);

  /**
   * Single-run convenience effect to fetch and set data for the component.
   *
   * Note: The additional passThroughProps conditional is included to ensure
   * inner-component state management logic support when in test mode.
   */
  React.useEffect(() => {
    async function fetchData() {
      if (!(journeyData || preload || passThroughProps?.data)) {
        setData(await fetchJourneyPreview({ journey }));
      } else {
        setData(journeyData || preload || passThroughProps?.data);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getIcon = (iconType) => {
    const iconId = uuidv4();
    let icon;
    let element;
    switch (iconType) {
      case 'audio':
        icon = listeningIcon;
        break;
      case 'video':
        icon = watchingIcon;
        break;
      case 'text':
        icon = writingIcon;
        break;
      default:
        icon = false;
        break;
    }
    if (icon) {
      element = <img alt="Icon" className="icon" key={iconId} src={icon} />;
    }
    return element;
  };

  const getNumParts = () => {
    return data?.content?.length || 0;
  };

  /**
   * Convenience function to call Segment analytics.
   *
   * @param {object} overrides - Optional object of override values.
   */
  function callAnalytics(overrides) {
    callSegmentTrack({
      event: overrides?.eventName || EVENTS.buttonAction,
      properties: {
        action: overrides?.action || ACTIONS.clicked,
        component: 'Video with Parts',
        component_url: overrides?.component_url,
        label: overrides?.label,
        logged_in: !!user,
        preferred_campus: null,
        referrer: document?.referrer || null,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
  }

  /**
   * Handler function for button item click.
   *
   * Note: Ignore directive added as it is only for test support to prevent the
   * navigation and setting of window location.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  function handleButtonClick(event) {
    /* istanbul ignore next */
    if (passThroughProps?.testOverride) {
      event.preventDefault();
    }
    callAnalytics({
      component_url: event?.currentTarget?.getAttribute('href'),
      label: event?.currentTarget?.textContent,
    });
  }

  /**
   * Handler function for link item click.
   *
   * Note: Ignore directive added as it is only for test support to prevent the
   * navigation and setting of window location.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  function handleLinkClick(event) {
    /* istanbul ignore next */
    if (passThroughProps?.testOverride) {
      event.preventDefault();
    }
    callAnalytics({
      component_url: event?.currentTarget?.getAttribute('href'),
      label: event?.currentTarget?.textContent,
    });
  }

  return (
    <div
      className={`video-with-parts-wrapper ${appliedTheme} bg-${
        background || 'none'
      }`}
      data-testid="lc-video-with-parts-wrapper"
    >
      {data && Object.keys(data).length ? (
        <div
          className="video-with-parts-container container"
          data-testid="lc-video-with-parts-content"
        >
          <div className="left-content">
            {data.featuredVideo ? (
              <Video youtubeID={data.featuredVideo} />
            ) : (
              <LCBackground
                className="video-wrapper"
                imgixParams={{ ar: '16:9' }}
                src={data.image || ''}
              />
            )}
          </div>
          <div className="right-content">
            <h2 className="title">
              <a
                className="title"
                href={`/journeys/${data?.slug}`}
                onClick={handleLinkClick}
              >
                {data?.title || 'Journey'}
              </a>
            </h2>
            <span
              className="parts-tag"
              data-testid="lc-video-with-parts-tag-length"
            >
              {getNumParts()} part{getNumParts() > 1 ? 's' : ''}
            </span>
            <hr className="splitter" />
            {data?.content &&
              data?.content.length > 0 &&
              data?.content?.map((article, i, { length }) => (
                <div className="icon-text-wrapper" key={article['@name']}>
                  <div className="icon-wrapper">
                    {getIcon(article.type)}
                    {i < length - 1 ? <div className="line" /> : null}
                  </div>
                  <a
                    className="text"
                    data-testid={`lc-video-with-parts-article-link-${article['@name']}`}
                    href={`/journeys/${data?.slug}/${article['@name']}`}
                    onClick={handleLinkClick}
                  >
                    {article.title}
                  </a>
                </div>
              ))}
            <ButtonItem
              className="start-button"
              data-testid="lc-video-with-parts-start-button"
              onClick={handleButtonClick}
              style="btn-primary"
              text="Start Journey"
              url={`/journeys/${data?.slug}`}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default VideoWithParts;
