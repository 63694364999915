/**
 * @module Quote
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import './Quote.scss';
import { COLOR_THEMES } from '../../helpers';

/**
 * Represents a container element with a quote and quote source.
 *
 * @param {object} props - The component props object.
 * @param {boolean} [props.isDarkMode] - Optional boolean flag denoting whether or not the quote is in dark mode (Default: false).
 * @param {string} props.quote - The quote text string.
 * @param {string} props.source - The quote source string.
 *
 * @returns {React.ReactElement} The Quote component.
 */
const Quote = ({ isDarkMode, quote, source }) => {
  if (!quote && !source) {
    return null;
  }

  const modeClassName = isDarkMode
    ? COLOR_THEMES['dark-mode']
    : COLOR_THEMES['light-mode'];
  return (
    <div className={`quote-wrapper ${modeClassName} container`}>
      {quote ? <h2 className="title">{quote}</h2> : null}
      {source ? <p className="source">{source}</p> : null}
    </div>
  );
};

export default Quote;
