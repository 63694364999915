/**
 * @module Search
 */
import React from 'react';
import { callSegmentTrack } from '@io/web-tools-io/dist/utils/helpers/analytics';
import useAuth from '@io/web-tools-io/dist/hooks/useAuth';
import { InstantSearch, useHits } from 'react-instantsearch';
import { ACTIONS, EVENTS } from '../../helpers/constants';
import FilterSelect from '../FilterSelect/FilterSelect';
import useAlgoliaClient from '../../hooks/useAlgoliaClient';
import FindsSearchBox from '../FindsSearchBox/FindsSearchBox';
import ArticleCard from '../ArticleCard/ArticleCard';
import './Search.scss';

const Search = ({ searchModalHandler }) => {
  const { user } = useAuth();
  const searchClient = useAlgoliaClient();
  const [hitNum, setHitNum] = React.useState(0);
  const [order, setOrder] = React.useState('new');
  const [numToDisplay, setNumToDisplay] = React.useState(4);
  const appliedTheme = 'light-mode';

  /**
   * Convenience function to trigger callSegmentTrack.
   *
   * @param {object} params - The function params object.
   * @param {string} [params.action] - Optional value for action.
   * @param {string} [params.eventName] - Optional event associated with the tracking request.
   * @param {string} [params.label] - Optional value for label.
   * @param {string} [params.value] - Optional value associated with the tracking request.
   */
  function callAnalytics({ action, eventName, label, value }) {
    callSegmentTrack({
      event: eventName || EVENTS.buttonAction,
      properties: {
        action: action || ACTIONS.clicked,
        component: 'Search',
        component_url: null, // Inner-component scroll, no component URL.
        label,
        logged_in: !!user,
        preferred_campus: null,
        referrer: document?.referrer || null,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
        value,
      },
    });
  }

  /**
   * Handler function for search close.
   *
   * @param {Event} event - The Event object associated with the action.
   */
  function handleSearchClose(event) {
    callAnalytics({
      action: EVENTS.buttonAction,
      event,
      label: 'Close',
    });
    searchModalHandler(event);
  }

  const sortArticles = (list) => {
    if (!list?.length) {
      return [];
    }
    const copy = [...list];
    switch (order) {
      case 'new':
        return copy.sort((j1, j2) =>
          j1?.data?.startDate < j2?.data?.startDate ? 1 : -1,
        );
      case 'old':
        return copy.sort((j1, j2) =>
          j1?.data?.startDate < j2?.data?.startDate ? -1 : 1,
        );
      case 'z':
        return copy.sort((j1, j2) =>
          j1?.data?.title?.toUpperCase() < j2?.data?.title?.toUpperCase()
            ? 1
            : -1,
        );
      case 'a':
      default:
        return copy.sort((j1, j2) =>
          j1?.data?.title?.toUpperCase() > j2?.data?.title?.toUpperCase()
            ? 1
            : -1,
        );
    }
  };

  /**
   * Handler function for filter sort change event.
   *
   * @param {Event} event - The Event object associated with the sort trigger.
   */
  function handleFilterSort(event) {
    callAnalytics({
      action: ACTIONS.changed,
      eventName: EVENTS.formAction,
      label: 'Filter Sort',
      value: event?.target?.value,
    });
    setOrder(event?.target?.value);
  }

  /**
   * Handler function for Load More button click event.
   */
  function loadMore() {
    callAnalytics({
      action: ACTIONS.clicked,
      eventName: EVENTS.buttonAction,
      label: 'Load More',
    });
    setNumToDisplay(numToDisplay + 4);
  }

  const getArticlesToDisplay = (list) => {
    if (!list?.length) {
      return list;
    }
    const copy = [...list];
    return copy.splice(0, numToDisplay);
  };

  const transformItems = (items) => {
    if (!items?.length) {
      setHitNum(0);
      return items;
    }
    let transformed = items.map((item) => {
      const newItem = item;
      const id = item.objectID;
      const n = id.lastIndexOf('/');
      newItem.data['@name'] = id.substring(n + 1);
      return newItem;
    });
    if (transformed.length !== hitNum) {
      setHitNum(transformed.length);
    }
    transformed = getArticlesToDisplay(sortArticles(transformed));
    return transformed;
  };

  function ArticleHits() {
    const { hits } = useHits({ transformItems });
    return (hits || []).map((hit) => (
      <ArticleCard
        fetchedArticle={hit.data}
        key={hit.objectID}
        theme={appliedTheme}
      />
    ));
  }

  return (
    <div className="search-wrapper">
      <InstantSearch
        indexName={process.env.FINDS_ALGOLIA_INDEX_ARTICLES}
        searchClient={searchClient}
      >
        <div className="close-button-wrapper">
          <button className="action-button" onClick={handleSearchClose}>
            Close
          </button>
        </div>
        <div className="contents-container">
          <h2 className="title">Search</h2>
          <div className="flex-content">
            <FindsSearchBox
              isDisplayed={true}
              isFullSearch={true}
              theme={appliedTheme}
            />
            <FilterSelect
              onChange={(event) => {
                handleFilterSort(event);
              }}
              theme={appliedTheme}
            />
          </div>
          <div className="searched-contents">
            <ArticleHits />
          </div>
          {hitNum > numToDisplay ? (
            <div className="load-button-wrapper">
              <button className="action-button" onClick={loadMore}>
                Load more
              </button>
            </div>
          ) : null}
        </div>
      </InstantSearch>
    </div>
  );
};

export default Search;
