/**
 * @module Header
 */
import React from 'react';
import { callSegmentTrack } from '@io/web-tools-io/dist/utils/helpers/analytics';
import useAuth from '@io/web-tools-io/dist/hooks/useAuth';
import { stringToSlug } from '@io/web-tools-io/dist/utils/helpers/magnolia/urls';
import { implementUtmParams } from '@io/web-tools-io/dist/utils/helpers/utmParams';
import { ACTIONS, EVENTS } from '../../helpers/constants';
import logo from './finds-logo.svg';
import search from './header-search.svg';
import angleDown from '../../assets/finds-angle-down.svg';
import MenuModal from '../MenuModal/MenuModal';
import './Header.scss';

const Header = ({ menuData, searchModalHandler }) => {
  const { user } = useAuth();
  const [open, setOpen] = React.useState(false);

  /**
   * Handler function for button or link item element click.
   *
   * @param {Event} event - The Event object associated with the click.
   * @param {object} overrides - Optional object of override values.
   */
  function handleElementClick(event, overrides = {}) {
    callSegmentTrack({
      event: overrides?.eventName || EVENTS.buttonAction,
      properties: {
        action: overrides?.action || ACTIONS.clicked,
        component: overrides?.component || 'Header',
        component_url:
          overrides?.component_url ||
          event?.currentTarget?.getAttribute('href'),
        label: overrides?.label || event?.currentTarget?.textContent,
        logged_in: !!user,
        preferred_campus: null,
        referrer: document?.referrer || null,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
  }

  /**
   * Handler function for menu open event.
   *
   * @param {Event} event - The Event object associated with the event.
   */
  const handleOpenMenu = (event) => {
    /**
     * Ignore added but covered in test; ignoring due to in-component state
     * value and corresponding label Close/Open value.
     */
    /* istanbul ignore next */
    handleElementClick(event, {
      label: `Mobile Menu - ${open ? 'Close' : 'Open'}`,
    });
    setOpen(!open);
  };

  /**
   * Handler function for menu search button click.
   *
   * @param {Event} event - The Event object associated with the event.
   */
  const handleSearchClick = (event) => {
    handleElementClick(event, {
      label: 'Search Icon',
    });
    searchModalHandler(event);
  };

  const isContentNode = (menuItem) =>
    menuItem?.['@nodeType'] === 'mgnl:content';
  const rootMenu =
    (!!menuData && menuData.filter((menuItem) => isContentNode(menuItem))) ||
    [];

  /* istanbul ignore next */
  const menuFolder =
    (!!menuData &&
      menuData.filter(
        (menuItem) =>
          menuItem?.['@name'] === 'menu' &&
          menuItem?.['@nodeType'] === 'mgnl:folder',
      )[0]) ||
    null;

  /* istanbul ignore next */
  const menuSections =
    (!!menuFolder &&
      !!menuFolder['@nodes'] &&
      menuFolder['@nodes'].map((node) => menuFolder[node])) ||
    [];

  const getItemChildren = (rootMenuItem) => {
    return (
      !!rootMenuItem['@nodes'] &&
      rootMenuItem['@nodes']
        .filter((child) => isContentNode(rootMenuItem[child]))
        .map((child) => rootMenuItem[child])
    );
  };

  const getMenuItem = (rootMenuItem, stop = false) => {
    let url = false;
    if (
      !!rootMenuItem?.customLink?.field &&
      rootMenuItem.customLink.field === 'internal'
    ) {
      url =
        !!rootMenuItem.customLink.internal &&
        rootMenuItem.customLink.internal['@path'];
    } else if (
      !!rootMenuItem?.customLink?.field &&
      rootMenuItem.customLink.field === 'external'
    ) {
      url = rootMenuItem.customLink.external;
    }
    const children = getItemChildren(rootMenuItem);
    const hasChildren = children?.length > 0 && !stop;

    return (
      <li className="nav-desktop" key={rootMenuItem['@id']}>
        {rootMenuItem.customLink.field ? (
          <>
            <a
              data-menu-id={stringToSlug(rootMenuItem.name)}
              href={implementUtmParams(url, window?.location)}
              onClick={handleElementClick}
              rel="noreferrer"
              target={
                /**
                 * Ignore added due to openInNewTab being specified as both
                 * true and false in menu data but still not honored here in
                 * Jest test coverage.
                 */
                /* istanbul ignore next */
                !!rootMenuItem.openInNewTab &&
                rootMenuItem.openInNewTab === 'true'
                  ? '_blank'
                  : undefined
              }
            >
              <span className="modal-link-title">{rootMenuItem.name}</span>
              {hasChildren ? (
                <img alt="Arrow" className="down-arrow" src={angleDown} />
              ) : null}
            </a>
            {hasChildren ? (
              <ul className="sub-nav">
                {children.map((child) => {
                  return getMenuItem(child, true);
                })}
              </ul>
            ) : null}
          </>
        ) : null}
      </li>
    );
  };

  return (
    <header className="header" data-testid="lc-header">
      <nav className="navigation container">
        <a
          href={implementUtmParams('/', window?.location)}
          onClick={handleElementClick}
        >
          <img alt="Finds" className="logo" src={logo} />
        </a>
        <ul className={`main-nav ${open ? 'active' : ''}`}>
          {!!menuData &&
            !!rootMenu.length &&
            rootMenu.map((rootMenuItem) => {
              return getMenuItem(rootMenuItem);
            })}
        </ul>
        <ul className="menu-nav">
          <li>
            <button
              className="search"
              data-menu-id="search"
              onClick={handleSearchClick}
            >
              <img alt="Search" className="search" src={search} />
            </button>
          </li>
          <li>
            <button
              className="hamburger-menu"
              data-menu-id="hamburger-menu"
              onClick={handleOpenMenu}
            >
              <div className={`hamburger ${open ? 'active' : ''}`}>
                <span></span>
                <span></span>
                <span></span>
              </div>
            </button>
          </li>
        </ul>
      </nav>
      {open ? (
        <MenuModal
          menuSections={menuSections}
          onMenuItemSelect={({ func }) => {
            /* istanbul ignore next */
            if (func && typeof func === 'function') {
              func();
            }
            /* istanbul ignore next */
            setOpen(false);
          }}
          rootMenu={rootMenu}
        />
      ) : null}
    </header>
  );
};

export default Header;
