/**
 * @module JourneyDetailsHero
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { callSegmentTrack } from '@io/web-tools-io/dist/utils/helpers/analytics';
import useAuth from '@io/web-tools-io/dist/hooks/useAuth';
import { ACTIONS, EVENTS, FINISH_JOURNEY_URL } from '../../helpers/constants';
import LCImage from '../LCImage/LCImage';
import ArrowButton from '../ArrowButton/ArrowButton';
import ArticleNavigation from '../ArticleNavigation/ArticleNavigation';
import Tags from '../Tags/Tags';
import './JourneyDetailsHero.scss';

const JourneyDetailsHero = ({
  author,
  duration,
  image,
  gradientColor = 'dark',
  next,
  part,
  prev,
  journeyName,
  journeyUrl,
  tags = [],
  title,
}) => {
  const { user } = useAuth();

  /**
   * Convenience function to call Segment analytics.
   *
   * @param {Event} event - The Event object associated with the click.
   * @param {object} overrides - Optional object of override values.
   */
  /* istanbul ignore next*/
  function callAnalytics(event, overrides = {}) {
    callSegmentTrack({
      event: overrides?.eventName || EVENTS.buttonAction,
      properties: {
        action: overrides?.action || ACTIONS.clicked,
        component: overrides?.component || 'Journey Details Hero',
        component_url:
          overrides?.component_url ||
          event?.currentTarget?.getAttribute('href'),
        label: overrides?.label || event?.currentTarget?.textContent,
        logged_in: !!user,
        preferred_campus: null,
        referrer: document?.referrer || null,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
  }

  /**
   * Handler function for link click event.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  function handleLinkClick(event) {
    callAnalytics(event, { label: 'Back' });
  }

  /**
   * Handler function for navigation button link click event.
   *
   * @param {Event} event - The Event object associated with the click.
   * @param {string} label - The label value of the button.
   */
  function handleNavigationButtonClick(event, label) {
    callAnalytics(event, { label });
  }

  return (
    <div
      className={`journey-details-hero-wrapper gradient-${gradientColor}`}
      data-testid="lc-journey-details-hero"
    >
      <div className="header-container">
        <div className="container">
          <a href={journeyUrl || '#'} onClick={handleLinkClick}>
            <ArrowButton
              alt="Back Icon"
              className="icon"
              orientation="left"
              theme={'light-mode'}
            />
          </a>
          <div className="text">
            <span className="black">Journey</span>
          </div>
          <p className="gold-text"> {journeyName}</p>
        </div>
      </div>
      <div className="background-gradient"></div>
      <LCImage className="background-image" src={image || ''} />
      <div className="content-wrapper container">
        <div className="bottom-content">
          <Tags tags={tags} />
          <h1 className="title">{title}</h1>
          <p className="author-duration">
            {author} {duration}
          </p>
          <span className="parts-tag">{`Part ${part}`}</span>
          <ArticleNavigation
            className="border-bottom"
            nextText={next?.text}
            nextUrl={next?.url === journeyUrl ? FINISH_JOURNEY_URL : next?.url}
            onNextClick={(event) => {
              handleNavigationButtonClick(event, next?.text || 'Next');
            }}
            onPrevClick={(event) => {
              handleNavigationButtonClick(event, prev?.text || 'Back');
            }}
            prevText={prev?.text}
            prevUrl={prev?.url}
          />
        </div>
      </div>
    </div>
  );
};

export default JourneyDetailsHero;
