/**
 * @module HubHero
 */

// eslint-disable-next-line no-unused-vars
import React from 'react';
import useWindowSize from '@io/web-tools-io/dist/hooks/useWindowSize';
import LCImage from '../LCImage/LCImage';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import { getAppliedTheme } from '../../helpers/getAppliedTheme';
import { COLOR_THEMES } from '../../helpers/constants';
import useTheme from '../../hooks/useTheme';
import './HubHero.scss';

const HubHero = ({ backgroundImage, gradientColor = 'dark', theme, title }) => {
  const { width } = useWindowSize();
  const { pageTheme } = useTheme();
  const appliedTheme = getAppliedTheme(pageTheme, theme);
  return (
    <div
      className={`hub-hero-wrapper ${appliedTheme} gradient-${gradientColor}`}
      data-testid="lc-hub-hero"
    >
      <div className="background-gradient"></div>
      <div className="image-gradient"></div>
      <LCImage className="image" src={backgroundImage || ''} width={width} />
      <Breadcrumbs isLightMode={appliedTheme === COLOR_THEMES['light-mode']} />
      <div className="content-wrapper">
        <h1 className="title">{title}</h1>
      </div>
    </div>
  );
};

export default HubHero;
