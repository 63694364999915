/**
 * @module Share
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { callSegmentTrack } from '@io/web-tools-io/dist/utils/helpers/analytics';
import useAuth from '@io/web-tools-io/dist/hooks/useAuth';
import { ACTIONS, EVENTS } from '../../helpers/constants';
import Spans from '../IconSpans/Spans';
import './Share.scss';

/**
 * Represents a lineup of share icons.
 *
 * @param {object} props - The component props object.
 * @param {ShareData} props.data - Object of share data.
 * @param {boolean} [props.email] - Optional boolean flag denoting whether or not to include email as a social provider.
 * @param {string} [props.emailUrl] - Optional URL value for email social provider.
 * @param {boolean} [props.facebook] - Optional boolean flag denoting whether or not to include Facebook as a social provider.
 * @param {string} [props.facebookUrl] - Optional URL value for Facebook social provider.
 * @param {boolean} [props.instagram] - Optional boolean flag denoting whether or not to include Instagram as a social provider.
 * @param {string} [props.instagramUrl] - Optional URL value for Instagram social provider.
 * @param {boolean} [props.linkedin] - Optional boolean flag denoting whether or not to include LinkedIn as a social provider.
 * @param {string} [props.linkedinUrl] - Optional URL value for LinkedIn social provider.
 * @param {boolean} [props.showShareTitle] - Optional boolean flag denoting whether or not to include the Share title alongside the lineup of icons.
 * @param {boolean} [props.tiktok] - Optional boolean flag denoting whether or not to include Tiktok as a social provider.
 * @param {string} [props.tiktokUrl] - Optional URL value for Tiktok social provider.
 * @param {boolean} [props.twitter] - Optional boolean flag denoting whether or not to include Twitter/X as a social provider.
 * @param {string} [props.twitterUrl] - Optional URL value for Twitter/X social provider.
 * @param {boolean} [props.youtube] - Optional boolean flag denoting whether or not to include YouTube as a social provider.
 * @param {string} [props.youtubeUrl] - Optional URL value for YouTube social provider.
 *
 * @returns {React.ReactElement} The Share component.
 */
function Share({
  data,
  email = true,
  emailUrl = '',
  facebook = true,
  facebookUrl = '',
  instagram = true,
  instagramUrl = '',
  showShareTitle = false,
  linkedin = true,
  linkedinUrl = '',
  tiktok = false,
  tiktokUrl = '',
  twitter = true,
  twitterUrl = '',
  youtube = true,
  youtubeUrl = '',
}) {
  const { user } = useAuth();
  const siteUrl = window.location.href;
  const emailBody = (data?.description || data?.headline) ?? '';

  /**
   * Convenience array of media share icons.
   */
  const mediaShareIcons = [
    {
      iconSlug: 'youtube',
      label: 'YouTube',
      url: youtube ? youtubeUrl : null,
    },
    {
      iconSlug: 'instagram',
      label: 'Instagram',
      url: instagram ? instagramUrl : null,
    },
    {
      iconSlug: 'facebook',
      label: 'Facebook',
      url: facebook
        ? facebookUrl || `https://facebook.com/sharer/sharer.php?u=${siteUrl}`
        : null,
    },
    {
      iconSlug: 'twitter-x',
      label: 'X',
      url: twitter
        ? twitterUrl ||
          `https://twitter.com/intent/tweet?text=${
            data?.title || ''
          }&url=${encodeURIComponent(siteUrl)}`
        : null,
    },
    {
      iconSlug: 'tiktok',
      label: 'TikTok',
      url: tiktok ? tiktokUrl : null,
    },
    {
      iconSlug: 'email',
      label: 'Email',
      url: email
        ? emailUrl ||
          `mailto:?subject=${data?.title}&body=${emailBody}%0D%0A${encodeURI(
            siteUrl,
          )}`
        : null,
    },
    {
      iconSlug: 'linkedin',
      label: 'LinkedIn',
      url: linkedin ? linkedinUrl : null,
    },
  ];

  // Convenience array of filtered media share icons based on URL value.
  const filteredMediaShareIcons = mediaShareIcons.filter(
    (shareIcon) => shareIcon.url,
  );

  /**
   * Handler function for button click.
   *
   * @param {Event} event - The Event object associated with the click.
   * @param {string} [label] - Optional label to use for analytics properties data object.
   */
  function handleButtonClick(event, label) {
    callSegmentTrack({
      event: EVENTS.buttonAction,
      properties: {
        action: ACTIONS.clicked,
        component: 'Share',
        component_url: event?.currentTarget?.getAttribute('href'),
        label: label || event?.currentTarget?.textContent,
        logged_in: !!user,
        preferred_campus: null,
        referrer: document?.referrer || null,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
  }

  return filteredMediaShareIcons?.length ? (
    <div className="share-wrapper" data-testid="lc-share">
      {showShareTitle ? <p className="subtitle">Share</p> : null}
      <div className="social-media-links">
        {filteredMediaShareIcons.map((shareIcon) => {
          return (
            <a
              className="social-link"
              href={shareIcon.url}
              key={`share-icon-${shareIcon.iconSlug}`}
              onClick={handleButtonClick}
              rel="noreferrer"
              target="_blank"
            >
              <span className={`icon icon-${shareIcon.iconSlug}`}>
                <Spans />
              </span>
            </a>
          );
        })}
      </div>
    </div>
  ) : null;
}

export default Share;
