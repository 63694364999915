/**
 * @module ArticlePageHero
 */
import React from 'react';
import { Log, convertToCmsAssetUrl } from '@io/web-tools-io/dist/utils/helpers';
import useWindowSize from '@io/web-tools-io/dist/hooks/useWindowSize';
import LCImage from '../LCImage/LCImage';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import Tags from '../Tags/Tags';
import FlexContent from './FlexContent';
import './ArticlePageHero.scss';

/**
 * Represents a hero image and content section for an Article page.
 *
 * @param {object} props - The component props object.
 * @param {string} props.author - The article author name.
 * @param {string} [props.backgroundVideo] - Optional URL value for a background video.
 * @param {string} props.buttonLabel - The button label text value.
 * @param {string} props.description - The description of the article.
 * @param {string} props.duration - The duration of the article read time.
 * @param {string} [props.gradientColor] - Optional gradient color value to use as overlay of the video/image (Default: 'gray').
 * @param {boolean} props.hasFeaturedVideo - Boolean flag denoting whether or not the component has a featured video.
 * @param {string} props.image - URL for the article hero image.
 * @param {string} props.name - The name of the page.
 * @param {Array} props.tags - Optional array of tags associated with the article (Default: []).
 * @param {Function} [props.toggleModal] - Handler function for featured video button item click event.
 * @param {string} props.title - The article title value.
 *
 * @returns {React.ReactElement} - The ArticlePageHero component.
 */
function ArticlePageHero({
  author,
  backgroundVideo,
  buttonLabel,
  description,
  duration,
  gradientColor = 'dark',
  hasFeaturedVideo,
  image,
  name,
  tags = [],
  toggleModal,
  title,
}) {
  const { isMobile } = useWindowSize();
  const [finalVideoBackground, setFinalVideoBackground] = React.useState();
  const flexContentProps = {
    buttonLabel,
    data: { description, title },
    hasFeaturedVideo,
    toggleModal,
  };

  const convertUrl = React.useCallback(async () => {
    try {
      const cmsAssetUrl = await convertToCmsAssetUrl({
        cmsAssetsBaseUrl: process.env.CMS_ASSETS_BASE_URL,
        imgixBaseUrl: process.env.IMGIX_BASE_URL,
        source: backgroundVideo,
      });
      setFinalVideoBackground(cmsAssetUrl);
    } catch (error) {
      /* istanbul ignore next */
      Log.error(error);
    }
  }, [backgroundVideo]);

  /**
   * Convenience effect to trigger url conversion to CMS Asset.
   */
  React.useEffect(() => {
    convertUrl();
  }, [backgroundVideo, convertUrl]);

  return (
    <div
      className={`article-page-hero-wrapper gradient-${gradientColor}`}
      data-testid="lc-article-page-hero"
    >
      <div className="main-content-wrapper">
        <div className="background-gradient"></div>
        {finalVideoBackground ? (
          <video
            autoPlay="autoplay"
            className="background-image video-background"
            loop={true}
            muted={true}
            style={{ maxWidth: '100%' }}
          >
            <source src={finalVideoBackground} />
          </video>
        ) : (
          <LCImage
            alt={'background'}
            className="background-image"
            src={image || ''}
          />
        )}
        <Breadcrumbs initPageNames={['Articles', name]} isArticle={true} />
        <div className="content-wrapper container">
          <div className="bottom-content">
            <Tags tags={tags} />
            <h1 className="title">{title}</h1>
            <p className="author-duration">
              {[author, duration].filter(Boolean).join(' • ')}
            </p>
            {!isMobile ? <FlexContent {...flexContentProps} /> : null}
          </div>
        </div>
      </div>
      {isMobile ? (
        <FlexContent
          className="container"
          showShareTitle={true}
          {...flexContentProps}
        />
      ) : null}
    </div>
  );
}

export default ArticlePageHero;
