/**
 * @module JourneyHero
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import useWindowSize from '@io/web-tools-io/dist/hooks/useWindowSize';
import LCImage from '../LCImage/LCImage';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import Tags from '../Tags/Tags';
import './JourneyHero.scss';

/**
 * Represents a wrapper containing large background image overlayed with breadcrumb links, and journey-related content including tags, title, and parts.
 *
 * @param {object} props - The component props object.
 * @param {string} [props.image] - Optional Journey image src URL.
 * @param {string} [props.name] - Journey name value. If not specified, no markup will be returned.
 * @param {string} [props.parts] - The parts value of the Journey.
 * @param {Array} [props.tags] - Optional array of tags associated with the article (Default: []).
 * @param {string} [props.title] - The Journey title.
 *
 * @returns {React.ReactElement} The JourneyHero component.
 */
const JourneyHero = ({ image, name, parts, tags, title }) => {
  const { width } = useWindowSize();

  return name ? (
    <div className="journey-hero-wrapper">
      <div className="background-gradient"></div>
      <LCImage className="background-image" src={image || ''} width={width} />
      <Breadcrumbs initPageNames={['Journeys', title]} />
      <div className="content-wrapper container">
        <div className="centered-content">
          {tags?.length ? <Tags tags={tags} /> : null}
          {title ? <h1 className="title">{title}</h1> : null}
          {!!parts ? <span className="parts-tag">{parts}</span> : null}
        </div>
      </div>
    </div>
  ) : null;
};

export default JourneyHero;
