/**
 * @module MagnoliaConfig
 */

import SingleContentZone from './components/SingleContentZone/SingleContentZone';
import DualContentZone from './components/DualContentZone/DualContentZone';
import Quote from './components/Quote/Quote';
import ArticleCard from './components/ArticleCard/ArticleCard';
import InstallApp from './components/InstallApp/InstallApp';
import NextSteps from './components/NextSteps/NextSteps';
import HubHero from './components/HubHero/HubHero';
import HomeHero from './components/HomeHero/HomeHero';
import CardWithTags from './components/CardWithTags/CardWithTags';
import TextImageCTA from './components/TextImageCTA/TextImageCTA';
import HubPreview from './components/HubPreview/HubPreview';
import VideoWithParts from './components/VideoWithParts/VideoWithParts';
import ImageWithParts from './components/ImageWithParts/ImageWithParts';
import Basic from './pages/Basic';
import Article from './pages/Article';

import AncherNavigation from './components/AncherNavigation/AncherNavigation';
import EmbedItem from './components/EmbedItem/EmbedItem';
import Heading from './components/Heading/Heading';
import MediaAsset from './components/MediaAsset/MediaAsset';
import ButtonItem from './components/ButtonItem/ButtonItem';
import RichText from './components/RichText/RichText';

import Spacer from './components/Spacer/Spacer';
import Video from './components/Video/Video';
import JourneyDetail from './components/JourneyDetail/JourneyDetail';
import JourneysListing from './components/JourneysListing/JourneysListing';
import JourneyListingHero from './components/JourneyListingHero/JourneyListingHero';
import ArticlesListing from './components/ArticlesListing/ArticlesListing';
import TopicsListing from './components/TopicsListing/TopicsListing';
import JourneyCard from './components/JourneyCard/JourneyCard';
import Carousel from './components/Carousel/Carousel';
import AudioWrapper from './components/AudioWrapper/AudioWrapper';
import Sidebar from './components/Sidebar/Sidebar';
import FeaturedArticles from './components/FeaturedArticles/FeaturedArticles';
import CompositeButtonGroup from './components/CompositeButtonGroup/CompositeButtonGroup';

const config = {
  componentMappings: {
    // Components
    'lifechurch:components/buttonitem': ButtonItem,
    'lifechurch:components/compositebuttongroup': CompositeButtonGroup,
    'lifechurch:components/dualcontentzone': DualContentZone,
    'lifechurch:components/embeddableform': EmbedItem,
    'lifechurch:components/embeditem': EmbedItem,

    'lifechurch:components/finds/anchernavigation': AncherNavigation,
    'lifechurch:components/finds/articlecard': ArticleCard,
    'lifechurch:components/finds/articlelisting': ArticlesListing,
    'lifechurch:components/finds/audioplayer': AudioWrapper,
    'lifechurch:components/finds/cardwithtags': CardWithTags,
    'lifechurch:components/finds/carousel': Carousel,
    'lifechurch:components/finds/featuredarticles': FeaturedArticles,
    'lifechurch:components/finds/homehero': HomeHero,
    'lifechurch:components/finds/hubhero': HubHero,
    'lifechurch:components/finds/hubpreview': HubPreview,
    'lifechurch:components/finds/imagewithparts': ImageWithParts,
    'lifechurch:components/finds/installapp': InstallApp,
    'lifechurch:components/finds/journeycard': JourneyCard,
    'lifechurch:components/finds/journeydetail': JourneyDetail,
    'lifechurch:components/finds/journeylisting': JourneysListing,
    'lifechurch:components/finds/journeylistinghero': JourneyListingHero,
    'lifechurch:components/finds/nextsteps': NextSteps,
    'lifechurch:components/finds/quote': Quote,
    'lifechurch:components/finds/sidebar': Sidebar,
    'lifechurch:components/finds/textimagecta': TextImageCTA,
    'lifechurch:components/finds/topiclisting': TopicsListing,
    'lifechurch:components/finds/videowithparts': VideoWithParts,

    'lifechurch:components/heading': Heading,
    'lifechurch:components/mediaasset': MediaAsset,
    'lifechurch:components/richtext': RichText,
    'lifechurch:components/richtextopen': RichText,
    'lifechurch:components/singlecontentzone': SingleContentZone,
    'lifechurch:components/spacer': Spacer,
    'lifechurch:components/video': Video,

    // Page Templates
    'lifechurch:pages/findsarticles': Article,
    'lifechurch:pages/findsbase': Basic,
    'lifechurch:pages/findshub': Basic,
  },
};

export default config;
