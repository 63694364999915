/**
 * @module NextStepsRowLayout
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { callSegmentTrack } from '@io/web-tools-io/dist/utils/helpers/analytics';
import useAuth from '@io/web-tools-io/dist/hooks/useAuth';
import { ACTIONS, EVENTS } from '../../helpers/constants';

/**
 * Renders the next steps content in a row layout.
 *
 * @param {object} props - The component props object.
 * @param {Array} props.content - An array of objects representing the content items.
 *
 * @returns {React.Element} The rendered NextStepsRowLayout component.
 */
function NextStepsRowLayout({ content }) {
  const { user } = useAuth();

  if (!content?.length) {
    return null;
  }

  /**
   * Handler function for element click.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  function handleElementClick(event) {
    callSegmentTrack({
      event: EVENTS.buttonAction,
      properties: {
        action: ACTIONS.clicked,
        component: 'Next Steps',
        component_url: event?.currentTarget?.getAttribute('href'),
        label: event?.currentTarget?.textContent,
        logged_in: !!user,
        preferred_campus: null,
        referrer: document?.referrer || null,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
  }

  return content.map((item) => (
    <div className="next-steps-row" key={item.id}>
      <p className="small-subtitle">{item.forText}</p>
      <h3 className="big-subtitle">{item.forTarget}</h3>
      <div className="link">
        <a
          data-testid="next-steps-item-link"
          href={item.link}
          onClick={handleElementClick}
        >
          {item.label}
        </a>
        {item.type}
      </div>
      <hr />
    </div>
  ));
}

export default NextStepsRowLayout;
