/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/**
 * @module ArticlesListing
 */
import React, { memo } from 'react';
import { Configure, InstantSearch, useHits } from 'react-instantsearch';
import Slider from 'react-slick';
import { callSegmentTrack } from '@io/web-tools-io/dist/utils/helpers/analytics';
import useAuth from '@io/web-tools-io/dist/hooks/useAuth';
import useWindowSize from '@io/web-tools-io/dist/hooks/useWindowSize';
import FilterSelect from '../FilterSelect/FilterSelect';
import useAlgoliaClient from '../../hooks/useAlgoliaClient';
import ArticleCard from '../ArticleCard/ArticleCard';
import FindsSearchBox from '../FindsSearchBox/FindsSearchBox';
import ArrowButton from '../ArrowButton/ArrowButton';
import SearchResultsText from '../SearchResultsText/SearchResultsText';
import { ACTIONS, EVENTS } from '../../helpers/constants';
import { getAppliedTheme } from '../../helpers/getAppliedTheme';
import useTheme from '../../hooks/useTheme';
import './ArticlesListing.scss';

const ArticlesListing = (props) => {
  const { user } = useAuth();
  const urlParams = new URLSearchParams(window.location.search);
  const { theme } = props;
  const { pageTheme } = useTheme();
  const appliedTheme = getAppliedTheme(pageTheme, theme);
  const tagFilters =
    !!props.metadata && !!props.metadata['mgnl:tags']
      ? props.metadata['mgnl:tags']
      : [];
  const { isMobile } = useWindowSize();
  const searchClient = useAlgoliaClient();
  const [page, setPage] = React.useState(0);
  const [hitNum, setHitNum] = React.useState(0);
  const [order, setOrder] = React.useState('new');
  const [searchInput, setSearchInput] = React.useState('');
  const childRef = React.useRef(null);
  const getNumPerPage = () => (isMobile ? 2 : 10);
  const MAX_RESULTS = 50;
  let sliderRef = React.useRef(null);
  const settings = {
    accessibility: false,
    arrows: false,
    beforeChange: (old, curr) => {
      setPage(curr);
    },
    infinite: false,
  };
  const sectionTopRef = React.useRef(null);
  const scrollToSection = () => {
    if (sectionTopRef.current) {
      sectionTopRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const getActiveTag = (tag) => (tagFilters.includes(tag) ? tag : '');
  const [filter, setFilter] = React.useState(
    getActiveTag(urlParams.get('tag')?.toLowerCase()),
  );

  const sortArticles = (list) => {
    if (!list?.length) {
      return [];
    }
    const copy = [...list];
    switch (order) {
      case 'new':
        return copy.sort((j1, j2) =>
          j1?.data?.startDate < j2?.data?.startDate ? 1 : -1,
        );
      case 'old':
        return copy.sort((j1, j2) =>
          j1?.data?.startDate < j2?.data?.startDate ? -1 : 1,
        );
      case 'z':
        return copy.sort((j1, j2) =>
          j1?.data?.title?.toUpperCase() < j2?.data?.title?.toUpperCase()
            ? 1
            : -1,
        );
      case 'a':
      default:
        return copy.sort((j1, j2) =>
          j1?.data?.title?.toUpperCase() > j2?.data?.title?.toUpperCase()
            ? 1
            : -1,
        );
    }
  };

  const getMaxPage = () => {
    return Math.ceil(hitNum / getNumPerPage()) || 1;
  };

  const getPages = () => {
    let n = hitNum;
    const slides = [];
    while (n > 0) {
      const toTake = Math.min(n, getNumPerPage());
      slides.push(<ArticleHits num={toTake} start={hitNum - n} />);
      n -= toTake;
    }
    const pages = slides.map((s, i) => (
      <div className="contents" key={`page-${i}`}>
        {s}
      </div>
    ));
    if (!pages.length) {
      pages.push(<div className="contents"></div>);
    }
    return pages;
  };

  const transformItems = (items) => {
    if (!items?.length) {
      return items;
    }
    let transformed = sortArticles(items);
    transformed.length = Math.min(transformed.length, MAX_RESULTS);
    transformed = transformed.map((item) => {
      const newItem = item;
      const id = item.data?.path;
      const n = id.lastIndexOf('/');
      newItem.data['@name'] = id.substring(n + 1);
      return newItem;
    });
    return transformed;
  };

  const ArticleHits = memo(function ArticleHits({
    start = 0,
    num = 2,
    empty = false,
  }) {
    const { hits } = useHits({ transformItems });
    setHitNum(hits.length);
    if (empty) {
      return <></>;
    }

    return hits.splice(start, num).map((hit) => {
      const article = hit.data;
      article['mgnl:tags'] = article.topics || article['mgnl:tags'];
      return (
        <ArticleCard
          fetchedArticle={article}
          isSearchResult={true}
          key={hit.data?.path}
          theme={appliedTheme}
        />
      );
    });
  }); // NOSONAR

  const setActiveTag = (tag) => {
    if (getActiveTag(tag)) {
      setFilter(tag !== filter ? tag : '');
    }
    sliderRef.slickGoTo(0, true);
  };

  /**
   * Convenience function to trigger callSegmentTrack.
   *
   * @param {object} params - The function params object.
   * @param {string} [params.action] - Optional value for action.
   * @param {string} [params.event] - Optional event associated with the tracking request.
   * @param {string} [params.label] - Optional value for label.
   * @param {string} [params.value] - Optional value associated with the tracking request.
   */
  function callAnalytics({ action, event, label, value }) {
    callSegmentTrack({
      event: event || EVENTS.buttonAction,
      properties: {
        action: action || ACTIONS.clicked,
        component: 'Articles Listing',
        component_url: null, // Inner-component scroll, no component URL.
        label,
        logged_in: !!user,
        preferred_campus: null,
        referrer: document?.referrer || null,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
        value,
      },
    });
  }

  /**
   * Handler function for Back button click.
   */
  function handleBackClick() {
    callAnalytics({ label: 'Back' });
    sliderRef.slickPrev();
    setTimeout(() => {
      scrollToSection();
    }, 300);
  }

  /**
   * Handler function for Next button click.
   */
  function handleNextClick() {
    callAnalytics({ label: 'Next' });
    sliderRef.slickNext();
    setTimeout(() => {
      scrollToSection();
    }, 300);
  }

  /**
   * Handler function for Next button click.
   *
   * @param {string} filterItem - The filter value.
   */
  function handleTagClick(filterItem) {
    callAnalytics({ label: filterItem });
    setActiveTag(filterItem);
  }

  /**
   * Handler function for filter change event.
   *
   * @param {Event} event - The Event object associated with the action.
   */
  function handleFilterChange(event) {
    callAnalytics({
      action: ACTIONS.changed,
      event: EVENTS.buttonAction,
      label: event.target.value,
    });
  }

  /**
   * Handler function for reset event.
   */
  function handleReset() {
    callAnalytics({ label: 'Reset' });
    if (childRef.current) {
      childRef.current.handleReset();
    }
  }

  const maxPage = getMaxPage();

  return (
    <div className={`articles-listing-wrapper ${appliedTheme}`}>
      <InstantSearch
        indexName={process.env.FINDS_ALGOLIA_INDEX_ARTICLES}
        searchClient={searchClient}
      >
        <Configure filters={filter ? `data.topics:${filter}` : ''} />
        <div className="filters" ref={sectionTopRef}>
          <div className="left-filters">
            <FindsSearchBox
              ref={childRef}
              setSearchInput={setSearchInput}
              theme={appliedTheme}
            />
            <ul>
              {tagFilters.map((f) => (
                <li
                  className={f === filter ? 'active-tag' : ''}
                  key={f}
                  onClick={() => handleTagClick(f)}
                >
                  {f}
                </li>
              ))}
            </ul>
          </div>
          <FilterSelect
            onChange={(event) => {
              handleFilterChange(event);
              setOrder(event.target.value);
            }}
            theme={appliedTheme}
          />
        </div>
        {searchInput?.length !== 0 ? (
          <SearchResultsText
            customTerm="Article"
            onClearResults={handleReset}
            resultCount={hitNum}
            searchText={searchInput}
          />
        ) : null}
        <ArticleHits empty={true} />
        <Slider
          {...settings}
          draggable={maxPage > 1}
          ref={(slider) => {
            sliderRef = slider;
          }}
        >
          {getPages()}
        </Slider>
        {hitNum ? (
          <div className="pagination">
            <ArrowButton
              alt="Back Icon"
              className="icon"
              onClick={handleBackClick}
              orientation="left"
              theme={appliedTheme}
            />
            <div className="counter">
              {page + 1} / {maxPage}
            </div>
            <ArrowButton
              alt="Next Icon"
              className="icon"
              onClick={handleNextClick}
              orientation="right"
              theme={appliedTheme}
            />
          </div>
        ) : null}
      </InstantSearch>
    </div>
  );
};

export default ArticlesListing;
