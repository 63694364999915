/**
 * @module SmallJourneyCard
 */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import LCImage from '../LCImage/LCImage';
import ButtonItem from '../ButtonItem/ButtonItem';
import Tags from '../Tags/Tags';
import './SmallJourneyCard.scss';

/**
 * Represents a container of Journey-related data including image, title, description, and call to action button.
 *
 * @param {object} props - The component props object.
 * @param {string} props.buttonLabel - The button label value.
 * @param {string} props.buttonLink - The button ULR link.
 * @param {string} [props.image] - Optional Journey image src URL.
 * @param {boolean} [props.isDragging] - Optional boolean flag indicating whether or not the component is being interacted with in a dragging event.
 * @param {Function} [props.onClick] - Optional handler function for the component's button click event.
 * @param {string} props.part - The part number value of the Journey.
 * @param {Array} props.tags - Optional array of tags associated with the article (Default: []).
 * @param {string} [props.title] - The Journey card title.
 *
 * @returns {React.ReactElement} The SmallJourneyCard component.
 */
const SmallJourneyCard = ({
  buttonLabel,
  buttonLink,
  image,
  isDragging,
  onClick,
  part,
  tags,
  title,
  ...passThroughProps
}) => {
  /**
   * Handler function for main element click event.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  function handleOnClick(event) {
    if (onClick && typeof onClick === 'function') {
      onClick(event);
    }
    /* istanbul ignore next */
    if (
      event.target.tagName.toLowerCase() !== 'a' &&
      buttonLink &&
      !passThroughProps?.testOverride
    ) {
      window.location.href = buttonLink;
    }
  }

  return (
    <div
      className={`small-journey-card-wrapper${
        isDragging ? ' disable-click' : ''
      } zoom-effect`}
      data-testid="lc-small-journey-card-wrapper"
      onClick={handleOnClick}
    >
      <div className="small-journey-card-container">
        <div className="image-container">
          <div className="gradient"></div>
          <LCImage className="image" src={image || ''} width={'320'} />
        </div>
        <div className="small-journey-card-content">
          {tags?.length ? <Tags tags={tags} /> : null}
          {title ? <h2 className="title">{title}</h2> : null}
          {!!part ? <span className="parts-tag">{`${part} parts`}</span> : null}
          {buttonLabel ? (
            <ButtonItem
              className="start-button"
              style="btn-primary"
              text={buttonLabel}
              url={buttonLink || '#'}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default SmallJourneyCard;
